import * as React from 'react';
import "./Step3.css"

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const steps = [
  'Itinéraire',
  'Options',
  'Passager',
  'Paiement',
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Step3({ onSubmit, formData, setFormData, handleChange, previousStep }) {

  const [errors, setErrors] = React.useState({});

  const validateForm = () => {
    const newErrors = {};
  
    if (!formData.passengerDetails.gender) {
      newErrors.gender = "Le genre est requis.";
    }
    if (!formData.passengerDetails.last_name) {
      newErrors.last_name = "Le nom est requis.";
    }
    if (!formData.passengerDetails.first_name) {
      newErrors.first_name = "Le prénom est requis.";
    }
    if (!formData.passengerDetails.phone_number) {
      newErrors.phone_number = "Le numéro de téléphone est requis.";
    }
    if (!formData.passengerDetails.email) {
      newErrors.email = "L'email est requis.";
    }
  
    setErrors(newErrors);
  
    // Retourne true si aucun champ n'est en erreur
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    if (validateForm()) {
      onSubmit(formData); // Passe à l'étape suivante uniquement si le formulaire est valide
    }
  };


    return (
        <>

          <Box sx={{ width: '100%' }} className="stepper">
            <Stepper activeStep={2} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <div className='container'>

            <div className='FormStep3Content'>
              <form onSubmit={handleFormSubmit}>

              PASSAGER

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Genre</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.passengerDetails.gender}
                  label="gender"
                  name="passengerDetails.gender"
                  onChange={handleChange}
                >
                  <MenuItem value='Monsieur'>Monsieur</MenuItem>
                  <MenuItem value='Madame'>Madame</MenuItem>
                  <MenuItem value='Mademoiselle'>Mademoiselle</MenuItem>
                  <MenuItem value='Autre'>Autre</MenuItem>
                </Select>
                {errors.gender && <span className="error">{errors.gender}</span>}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <TextField id="outlined-basic" label="Nom" variant="outlined" name="passengerDetails.last_name" value={formData.passengerDetails.last_name} onChange={handleChange} />
                {errors.first_name && <span className="error">{errors.last_name}</span>}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <TextField id="outlined-basic" label="Prénom" variant="outlined" name="passengerDetails.first_name" value={formData.passengerDetails.first_name} onChange={handleChange} />
                {errors.last_name && <span className="error">{errors.first_name}</span>}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <TextField id="outlined-basic" label="Portable" variant="outlined" name="passengerDetails.phone_number" type="tel" value={formData.passengerDetails.phone_number} onChange={handleChange} />
                {errors.phone_number && <span className="error">{errors.phone_number}</span>}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <TextField id="outlined-basic" label="Email" variant="outlined" name="passengerDetails.email" type="email" value={formData.passengerDetails.email} onChange={handleChange} />
                {errors.email && <span className="error">{errors.email}</span>}
              </FormControl>

              INFORMATIONS DE VOYAGE

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <TextField id="outlined-basic" label="Numéro de vol / train" variant="outlined" name="passengerDetails.flightNumber" value={formData.passengerDetails.flightNumber} onChange={handleChange} />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <TextField id="outlined-basic" label="Terminal" variant="outlined" name="passengerDetails.terminal" value={formData.passengerDetails.terminal} onChange={handleChange} />
              </FormControl>

              <div className='navigationButtons'>
                  <button type="button" onClick={previousStep} className='previousStepButton'>Retour</button>
                  <button type="submit" className='submitButton'>Suivant</button>
              </div>
                
              </form>     
            </div>

            <div className='CurrentInformations'>

              <div className='price_container_options'>
                <div className='price_inner_container'>
                  <p>Prix de la course</p>
                  <div className='price_content'>{formData.travelCalculateData.price} €</div>
                </div>
                <div className='price_options_inner_container'>
                  <p>Prix des options</p>
                  <div className='price_options_content'>{formData.options.price_option} €</div>
                </div>
              </div>

              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left">Temps estimé :</StyledTableCell>
                        <StyledTableCell align="left">{formData.travelCalculateData.estimated_time} min</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left">Distance de la course :</StyledTableCell>
                        <StyledTableCell align="left">{formData.travelCalculateData.distance} km</StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>

              <div>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  TRAJET
                </Typography>

                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Adresse de départ :</StyledTableCell>
                        <StyledTableCell align="left">{formData.adresse_start}, {formData.zipcode_start} {formData.city_start}, {formData.country_start}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Adresse d'arrivée :</StyledTableCell>
                        <StyledTableCell align="left">{formData.adresse_end}, {formData.zipcode_end} {formData.city_end}, {formData.country_end}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Date :</StyledTableCell>
                        <StyledTableCell align="left">{formData.date}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>L'heure de départ :</StyledTableCell>
                        <StyledTableCell align="left">{formData.departure_time}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Voiture :</StyledTableCell>
                        <StyledTableCell align="left">{formData.type_car}</StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>
              </div>

              <div>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  PASSAGERS
                </Typography>

                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Nombre de passagers :</StyledTableCell>
                        <StyledTableCell align="left">{formData.nb_passenger}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Nombres de bagages :</StyledTableCell>
                        <StyledTableCell align="left">{formData.nb_suitcase}</StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>
              </div>

              <div>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  OPTIONS
                </Typography>

                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Siège bébé :</StyledTableCell>
                        <StyledTableCell align="left">{formData.options.babySeat}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Réhausseur :</StyledTableCell>
                        <StyledTableCell align="left">{formData.options.nb_booster_seat}</StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>
              </div>
            </div>

          </div>

        </>
      );
    };
  