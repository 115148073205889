import "../Services.css"
import Button from '@mui/joy/Button';
import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import CarCard from "../../../components/CarCard/CarCard";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faStar, faBriefcase, faShield, faGear, faLeaf, faRoad } from '@fortawesome/free-solid-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const carFiles = ['businessCar','businessVan','luxuryCar'];
const carDataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));

const faqData = [
    {
        question: 'Le chauffeur peut-il m’attendre en cas de retard de vol à Orly ?',
        answer: "Oui, nous surveillons vos horaires de vol en temps réel pour ajuster notre prise en charge sans frais supplémentaires.",
    },
    {
        question: 'Proposez-vous des sièges pour enfants pour les trajets vers Orly ?',
        answer: "Oui, nous fournissons des sièges pour enfants sur demande. Assurez-vous d’ajouter cette option lors de votre réservation.",
    },
    {
        question: 'Quels sont les temps de trajet moyens vers Orly depuis Paris ?',
        answer: "Le trajet depuis Paris vers Orly dure environ 30 à 40 minutes, selon les conditions de circulation.",
    },
    {
        question: 'Quels types de véhicules sont disponibles pour les trajets vers Orly ?',
        answer: "Nous disposons de berlines, vans spacieux et véhicules premium pour répondre à toutes vos attentes.",
    },
];


const ServiceOrly = () => {

    const [carDataMap, setCarDataMap] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    
        // Importez dynamiquement les fichiers JSON
        const dataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));
        setCarDataMap(dataMap);
    
        // SEO Meta Tags
        document.title = "VTC Orly - Votre Transport Privé pour l'Aéroport | Tarif-VTC.fr";
        
        const metaDescription = document.createElement("meta");
        metaDescription.name = "description";
        metaDescription.content = 
            "Réservez un VTC pour vos transferts vers l'aéroport de Paris-Orly. Voyagez avec confort et sérénité grâce à nos services premium adaptés à vos besoins.";
        
        const metaKeywords = document.createElement("meta");
        metaKeywords.name = "keywords";
        metaKeywords.content = 
            "VTC Orly, transfert VTC Orly, chauffeur privé Orly, transport Orly aéroport, réservation VTC Orly, service premium VTC";
    
        // Ajoutez les métadonnées dans le head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
    
        return () => {
            // Nettoie les balises SEO pour éviter des duplications
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
        };
    }, []); // Le tableau vide en tant que deuxième argument signifie que cela s'exécutera une seule fois au chargement du composant
    
    return (
        <>
            <section className="service_section">

                <div className="service_aeroport_section_contentHead orly">
                    <img src="/logo_white_crop.webp" loading="lazy" alt="Tarif VTC logo"/>
                    <h1>VTC Orly avec Tarif-VTC : Service Premium et Confortable</h1>
                </div>


                <section className='head_title_section'>
                    <div className='head_title_content'>
                        <h2>Votre Chauffeur Privé Disponible en Île-de-France</h2>
                        <p className='header_section_subText'>
                            Profitez d’un service de <strong>VTC premium</strong> pour vos trajets vers <strong>l’aéroport d’Orly</strong>, disponible <strong>7j/7 et 24h/24</strong>. 
                            Réservez rapidement et voyagez en toute sérénité.
                        </p>
                        <div className='social_media_icons'>
                            <a href="https://www.facebook.com/drivigo" target="_blank" rel="noopener noreferrer">
                                <img src="/facebook_logo.png" loading="lazy" alt="Suivez-nous sur Facebook : Tarif VTC" />
                            </a>
                            <a href="https://www.instagram.com/mdprive_off/" target="_blank" rel="noopener noreferrer">
                                <img src="/instagram_logo.webp" loading="lazy" alt="Suivez-nous sur Instagram : Tarif VTC" />
                            </a>
                        </div>
                    </div>
                    <div className="reservation_link">
                        <Link to='/reservation'>
                            <Button variant="soft">Réservez un transfert</Button>
                        </Link>
                        <div className="reservation_link_right">
                            <a href="tel:0650240998">
                                <Button variant="soft">06.50.24.09.98</Button>
                            </a>
                        </div>
                    </div>
                </section>


                <div className="service_content_deck">
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                        <p className="title">Chauffeurs professionnels</p>
                        <p>Des chauffeurs expérimentés pour vous accompagner lors de vos trajets vers Orly.</p>
                    </div>
                    <div className="service_deck_separator"></div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faGear} className="iconCustom"/>
                        <p className="title">Flexibilité</p>
                        <p>Nous comprenons que vos horaires peuvent être variables et que vos exigences peuvent changer.</p>
                    </div>
                    <div className="service_deck_separator"></div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faRoad} className="iconCustom"/>
                        <p className="title">Porte à porte</p>
                        <p>De votre point de départ jusqu'à Orly, pour un trajet sans interruption.</p>
                    </div>
                </div>

                <div className="service_content_informations">
                    <img src='/aeroport2.webp' loading="lazy" alt="Voiture de luxe blanche garée devant un avion dans un hangar moderne à Orly" />
                    <div className="service_content_informations_text">
                        <h2>Transferts Premium vers l’Aéroport d’Orly avec Tarif-VTC</h2>
                        <p>
                            Simplifiez vos déplacements grâce à notre <strong>service de VTC pour l’aéroport d’Orly</strong>. Que ce soit pour un départ ou une arrivée, 
                            nous vous offrons un <strong>transport sur mesure</strong>, alliant confort, ponctualité et fiabilité.
                        </p>
                        <p>
                            Nos chauffeurs professionnels vous garantissent une expérience haut de gamme, adaptée à vos besoins. Avec des véhicules spacieux et bien équipés, 
                            votre trajet vers Orly devient une étape agréable et sans stress.
                        </p>
                        <ul>
                            <li><strong>Disponibilité 24h/24 et 7j/7 :</strong> Nos services s’adaptent à vos horaires, même pour les départs matinaux ou les arrivées tardives.</li>
                            <li><strong>Suivi des vols en temps réel :</strong> En cas de retard, votre chauffeur ajuste son arrivée pour garantir votre prise en charge.</li>
                            <li><strong>Voyage personnalisé :</strong> Des options comme des sièges pour enfants et de l’espace pour les bagages sont disponibles sur demande.</li>
                        </ul>
                        <p>
                            Faites de votre transfert vers <strong>Orly</strong> une expérience agréable avec <strong>Tarif-VTC</strong>. Réservez dès maintenant pour un service de transport fiable et premium.
                        </p>
                    </div>
                </div>


                <div className="service_content_informations bottom_infos">
                    <div className="service_content_informations_text">
                        <h2>Pourquoi Choisir notre VTC pour Vos Transferts vers Orly ?</h2>
                        <p>Découvrez les avantages uniques de notre service de **VTC pour Orly**, spécialement conçu pour répondre aux besoins des voyageurs :</p>
                        <ul>
                            <li><strong>Confort optimal :</strong> Profitez de véhicules spacieux, climatisés et équipés de sièges ergonomiques.</li>
                            <li><strong>Ponctualité garantie :</strong> Nos chauffeurs surveillent vos horaires de vol en temps réel pour s’assurer d’un transfert sans délai.</li>
                            <li><strong>Service personnalisé :</strong> Nos chauffeurs adaptent votre trajet selon vos besoins : bagages volumineux, sièges pour enfants, etc.</li>
                            <li><strong>Tarifs transparents :</strong> Pas de frais cachés, une tarification claire dès la réservation.</li>
                        </ul>
                    </div>
                    <img src="/vtc_gare.webp" loading="lazy" alt="Chauffeur privé aidant un client à Orly"/>
                </div>
            </section>

            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <h2>Réserver un VTC pour Orly : Simple, Rapide et Sécurisé</h2>
                        <p>
                            Organisez dès aujourd'hui votre transfert en <strong>VTC vers l’aéroport d’Orly (ORY)</strong> avec Tarif-VTC. Que ce soit pour un départ en vacances ou un voyage d'affaires, 
                            notre processus de réservation vous garantit une expérience fluide, sans stress, et parfaitement adaptée à vos besoins.
                        </p>
                        <h3>Les étapes pour réserver un VTC vers Orly :</h3>
                        <ul>
                            <li>
                                <strong>1. Choisissez votre itinéraire :</strong> Sélectionnez Orly comme aéroport de départ ou d'arrivée et précisez l’horaire souhaité pour une prise en charge optimale.
                            </li>
                            <li>
                                <strong>2. Personnalisez votre trajet :</strong> Indiquez le nombre de passagers, le volume de bagages, et sélectionnez le véhicule le mieux adapté 
                                (berline, van ou option premium). Ajoutez des options comme un siège enfant si nécessaire.
                            </li>
                            <li>
                                <strong>3. Réservez en ligne :</strong> Utilisez notre interface conviviale pour confirmer votre réservation en seulement quelques clics. Une confirmation instantanée vous sera envoyée par email, incluant tous les détails de votre trajet.
                            </li>
                        </ul>
                        <h3>Pourquoi choisir Tarif-VTC pour vos transferts vers Orly ?</h3>
                        <ul>
                            <li>
                                <strong>Disponibilité 24h/24 et 7j/7 :</strong> Nos chauffeurs sont à votre service pour les départs matinaux, les arrivées tardives ou tout autre horaire.
                            </li>
                            <li>
                                <strong>Suivi en temps réel des vols :</strong> Nous ajustons notre arrivée en fonction des éventuels retards ou avances de votre vol.
                            </li>
                            <li>
                                <strong>Tarifs transparents :</strong> Vous payez ce qui est convenu lors de la réservation, sans frais cachés.
                            </li>
                            <li>
                                <strong>Service premium :</strong> Profitez de véhicules confortables, d’une assistance bagages, et d’une expérience de transport haut de gamme.
                            </li>
                        </ul>
                    </div>
                    <div className="reservation-link">
                        <div className="reservation_link_right">
                            <Link to="/reservation">
                                <Button variant="soft">Réservez votre VTC en ligne</Button>
                            </Link>
                        </div>
                        <div className="reservation_link_right">
                            <a href="tel:0650240998">
                                <Button variant="soft">Appelez-nous : 06.50.24.09.98</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <section className="cars_list_section" id="cars_list">
                <div className="cars_list_container">
                    <h2>Notre Flotte de Véhicules pour vos Transferts vers Orly</h2>
                    <p>Nous proposons des véhicules modernes et adaptés à vos besoins pour garantir des trajets confortables et agréables, qu’il s’agisse de départs matinaux ou d’arrivées tardives à Orly.</p>
                    <div className="cars_list_cards">
                        {carDataMap.map((carData, index) => (
                            <CarCard 
                                imgUrl={carData.img} 
                                alt={`Véhicule ${carData.name} pour trajet Orly`} 
                                carType={carData.name} 
                                brand={carData.marque + ' ' + carData.model} 
                                passengerNb={carData.person} 
                                LuggageNb={carData.luggage} 
                                addLuggage={carData.additional_luggage} 
                                linkUrl={carData.linkUrl} 
                                key={index} 
                            />
                        ))}
                    </div>
                </div>
            </section>

            <section className="eco_section" id="eco">
                <div className="eco_container">
                    <h2>Un Engagement Écoresponsable pour Vos Trajets vers Orly</h2>
                    <p>Chez Tarif-VTC, nous intégrons des solutions durables à nos services de transport pour minimiser l’impact environnemental :</p>
                    <ul>
                        <li><strong>Flotte écologique :</strong> Nos véhicules incluent des options hybrides et électriques, alliant performance et respect de l’environnement.</li>
                        <li><strong>Optimisation des trajets :</strong> Nous planifions chaque trajet pour réduire les émissions inutiles et maximiser l’efficacité.</li>
                        <li><strong>Engagement envers la planète :</strong> Sensibilisation de nos chauffeurs et passagers pour des pratiques écoresponsables durant leurs déplacements.</li>
                    </ul>
                    <p>En optant pour nos services, vous contribuez à un avenir plus vert, sans compromettre votre confort ni votre tranquillité.</p>
                </div>
            </section>


            
            <section className="car_inside_services_section" id="car_inside_services">
                <div className="car_inside_services_container">
                    <h2>Services à Bord : Profitez d’un Confort Optimal Pendant Votre Trajet</h2>
                    <p>
                        Nos véhicules sont équipés pour garantir une expérience de conduite haut de gamme. Que vous voyagiez en famille, entre amis ou pour des affaires, 
                        profitez de prestations adaptées à vos besoins pour un trajet agréable et serein.
                    </p>
                    <div className="car_inside_services_content">
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBaby} className="iconCustom" />
                            <p>Siège enfant : sécurité et confort pour les plus petits.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBottleWater} className="iconCustom" />
                            <p>Eau : bouteilles fraîches offertes pour votre hydratation.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom" />
                            <p>Chargeurs : rechargez vos appareils pendant le trajet.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faWifi} className="iconCustom" />
                            <p>Wifi / Bluetooth : restez connecté tout au long du voyage.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faCreditCard} className="iconCustom" />
                            <p>Paiement à bord : flexible et sécurisé pour votre tranquillité.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                  <h3>FAQ - Service de VTC vers Orly</h3>
                  <h4>Posez-nous Vos Questions</h4>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section> 

            <section className="contact_section" id="contact">
                <div className="contact_content">
                    <div className="contact_content_left">
                        <h2>Contactez-nous pour Réserver Votre VTC vers Orly</h2>
                        <p>Notre équipe est disponible 24h/24 et 7j/7 pour répondre à vos questions et organiser vos transferts vers l’aéroport d’Orly. 
                        Réservez dès maintenant votre VTC pour un service premium, fiable et personnalisé.</p>
                        <a href="tel:0650240998"><Button variant="soft">Prendre un rendez-vous</Button></a>
                    </div>
                    <div className="contact_content_right">
                        <img src="/logo_white.webp" loading="lazy" alt="Logo Tarif-VTC.fr pour le contact" width={300} height={300}/>
                    </div>
                </div>
            </section>

        </>
    );
};
export default ServiceOrly;
