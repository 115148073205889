import "../Services.css"
import Button from '@mui/joy/Button';
import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import CarCard from "../../../components/CarCard/CarCard";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faStar, faBriefcase, faShield, faGear, faLeaf, faRoad, faClock, faInfoCircle, faPlaneDeparture} from '@fortawesome/free-solid-svg-icons'


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const carFiles = ['businessCar','businessVan','luxuryCar'];
//const carDataMap = carFiles.map((carFile) => require(`../../data/${carFile}.json`));
const carDataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));

const faqData = [
  {
      question: "Est-ce que le chauffeur attend si mon vol est retardé ?",
      answer: "Oui, nos chauffeurs suivent en temps réel l'évolution des horaires de vol grâce aux informations de suivi des vols et s'adaptent aux changements sans frais supplémentaires pour vous.",
  },
  {
      question: "Peut-on réserver un VTC pour un groupe ?",
      answer: "Oui, nous disposons de véhicules spacieux tels que des vans pouvant accueillir jusqu'à 7 passagers avec leurs bagages, parfaits pour les familles ou les groupes.",
  },
  {
      question: "Quels moyens de paiement sont acceptés ?",
      answer: "Vous pouvez régler en ligne lors de votre réservation ou directement auprès de notre chauffeur, par carte bancaire ou en espèces. Nos systèmes de paiement sont entièrement sécurisés.",
  },
  {
      question: "Comment réserver un VTC pour l'aéroport ?",
      answer: "Vous pouvez réserver facilement en ligne via notre site web ou par téléphone en appelant au 06.50.24.09.98. Notre processus est rapide et intuitif.",
  },
  {
      question: "Quels sont les avantages de choisir un VTC pour l'aéroport plutôt qu'un taxi ?",
      answer: "Avec nos VTC, vous bénéficiez de tarifs fixes et transparents, d'un confort premium, d'une réservation à l'avance et d'un service personnalisé adapté à vos besoins.",
  },
  {
      question: "Pouvez-vous m'aider avec mes bagages ?",
      answer: "Bien sûr ! Nos chauffeurs sont toujours prêts à vous aider avec vos bagages pour garantir une expérience sans stress dès votre prise en charge.",
  },
  {
      question: "Les VTC sont-ils disponibles pour les départs matinaux ou les arrivées tardives ?",
      answer: "Oui, nos services de VTC aéroport sont disponibles 24h/24 et 7j/7, adaptés à tous les horaires de vol, y compris les départs très matinaux et les arrivées tardives.",
  },
  {
      question: "Proposez-vous des sièges pour enfants dans les VTC ?",
      answer: "Oui, sur demande lors de la réservation, nous fournissons des sièges auto pour garantir la sécurité de vos enfants pendant le trajet.",
  },
  {
      question: "Quels sont les aéroports desservis par votre service de VTC ?",
      answer: "Nous desservons principalement les aéroports de Roissy Charles de Gaulle (CDG), Orly (ORY) et Beauvais (BVA). Nous pouvons également nous adapter à d'autres demandes spécifiques.",
  },
  {
      question: "Quels types de véhicules sont disponibles pour les trajets vers les aéroports ?",
      answer: "Notre flotte comprend des berlines confortables, des vans spacieux et des véhicules premium pour répondre à tous vos besoins, qu'ils soient individuels ou collectifs.",
  },
];


const ServiceAeroport = () => {

    const [carDataMap, setCarDataMap] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    
        // Importez dynamiquement les fichiers JSON
        const dataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));
        setCarDataMap(dataMap);
    
        // SEO Meta Tags
        document.title = "VTC Aéroport - Transferts Privés pour Roissy et Orly | Tarif-VTC.fr";
        
        const metaDescription = document.createElement("meta");
        metaDescription.name = "description";
        metaDescription.content = 
            "Réservez un VTC pour vos transferts vers les aéroports de Roissy-Charles de Gaulle et Orly. Profitez de services premium à prix compétitifs.";
        
        const metaKeywords = document.createElement("meta");
        metaKeywords.name = "keywords";
        metaKeywords.content = 
            "VTC aéroport, transfert VTC Roissy, transfert VTC Orly, VTC aéroport privé, réservation VTC aéroport";
    
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
    
        return () => {
            // Nettoie les balises SEO pour éviter des duplications
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
        };
    }, []); // Le tableau vide en tant que deuxième argument signifie que cela s'exécutera une seule fois au chargement du composant
  
  
    return (
        <>
            <section className="service_section">

                    <div className="service_aeroport_section_contentHead">
                        <img src="/logo_white_crop.webp" loading="lazy" alt="tarif vtc logo"/>
                        <h1>VTC Aéroport : Roissy Charles de Gaulle et Orly avec Tarif-VTC</h1>
                    </div>

                    <section className='head_title_section'>
                        <div className='head_title_content'>
                            <h2> Votre Chauffeur Privé pour les Aéroports de Roissy et Orly </h2>
                            <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide par <a href="tel:0650240998">téléphone</a></p>
                            <div className='social_media_icons'>
                            <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" loading="lazy" alt="tarif vtc facebook"/></a>
                            <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" loading="lazy" alt="tarif-vtc.fr logo instagram"/></a>
                            </div>
                        </div>
                        <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez un transfert </Button></Link><div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div></div>
                    </section> 


                    <div className="service_content_deck">
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                            <h3>Chauffeur professionels</h3>
                            <p>L&apos;expertise de chauffeurs professionnels pour vous accompagner lors de vos trajets.</p>
                        </div>
                        <div className="service_deck_separator"></div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faGear} className="iconCustom"/>
                            <h3>Flexibilité</h3>
                            <p>Nous comprenons que vos horaires peuvent être variables et que vos exigences peuvent changer.</p>
                        </div>
                        <div className="service_deck_separator"></div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faRoad} className="iconCustom"/>
                            <h3>Porte à porte</h3>
                            <p>Directement de votre lieu de départ à votre destination finale, en prenant en compte tous les détails de votre trajet. </p>
                        </div>
                    </div>

                    <div className="service_content_informations">
                        <img src='/aeroport2.webp' loading="lazy" alt="voiture de luxe blanche garé devant un avion gris et rouge dans un grand hangar blanc"/>
                        <div className="service_content_informations_text">
                            <h2>Transferts pour Roissy et Orly : Un Service Adapté à Vos Besoins</h2>
                            <p>Que vous voyagiez depuis Roissy-Charles de Gaulle, Orly, ou tout autre aéroport de Paris, notre service de VTC assure des transferts fiables, confortables, et adaptés à vos besoins. Avec nos chauffeurs professionnels et nos véhicules confortables, nous vous offrons une solution de transport privée adaptée à vos horaires et à vos besoins.</p>
                            <div className="link-aéroport">
                              <Link to='/nos-services/vtc-roissy'>En savoir plus sur les transferts à Roissy Charles de Gaulle</Link>
                              <Link to='/nos-services/vtc-orly'>En savoir plus sur les transferts à Orly</Link>
                            </div>
                        </div>
                    </div>



                    <div className="service_content_informations bottom_infos">
                        <div className="service_content_informations_text">
                            <h2>Pourquoi Choisir notre service de VTC pour Vos Transferts Aéroport ?</h2>
                            <p>Le choix d’un VTC pour vos transferts aéroport présente de nombreux avantages pour les voyageurs. Voici pourquoi notre service est le choix idéal pour rejoindre l’aéroport ou en revenir sans souci :</p>
                            <ul>
                                <li><strong>Confort et espace</strong> : Nos véhicules de transport aéroportuaire, spacieux et bien équipés, garantissent un trajet agréable et sécurisé, idéal après un vol long-courrier ou régional.</li>
                                <li><strong>Ponctualité et fiabilité</strong> : Nos chauffeurs suivent vos horaires de vol pour s'assurer que vous arriviez à l’heure à l'aéroport ou que vous soyez récupéré sans attendre à l’arrivée.</li>
                                <li><strong>Tranquillité d'esprit</strong> : Avec un VTC, vous évitez le stress du stationnement et du trafic, et vous bénéficiez d’un chauffeur privé dédié à votre confort.</li>
                            </ul>
                        </div>
                        <img src='/vtc_gare.webp' loading="lazy" alt="chauffeur privé ouvrant la porte et aider une cliente en costume à sortir d'une voiture privé mercedes"/>
                    </div>
            </section>

    

            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <h2>Comment Réserver un VTC pour l’Aéroport avec Tarif-VTC ?</h2>
                        <p>Planifiez facilement votre transfert privé avec un VTC depuis les aéroports de Roissy-Charles de Gaulle, Orly ou Beauvais grâce à notre service simple et rapide. Suivez ces étapes pour planifier votre prochain trajet :</p>
                        <ul>
                            <li><strong>Choisissez votre aéroport et votre heure de départ ou d'arrivée</strong> : Indiquez l'aéroport (Roissy-CDG, Orly, etc.) et l’horaire de votre vol pour garantir un service optimal.</li>
                            <li><strong>Personnalisez votre trajet</strong> : Indiquez vos besoins (nombre de passagers, espace pour bagages, sièges pour enfants).</li>
                            <li><strong>Réservez en ligne en quelques clics</strong> : Utilisez notre formulaire de réservation pour confirmer votre trajet et recevez une confirmation rapide avec tous les détails de votre voyage.</li>
                        </ul>
                        <p>Notre équipe reste à votre disposition pour répondre à toutes vos questions et vous accompagner dans la réservation de votre trajet en VTC vers ou depuis l’aéroport.</p>
                    </div>
                    <div className="reservation-link">
                        <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez un transfert</Button></Link></div>
                        <div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div>
                    </div>
                </div>
            </section>

            <section className="pricing_section" id="pricing">
                <div className="pricing_container">
                    <h2>Découvrez Nos Tarifs Compétitifs pour Vos Trajets vers les Aéroports</h2>
                    <p>
                        Avec Tarif-VTC, bénéficiez d'un service premium à un prix abordable pour vos trajets vers les principaux aéroports parisiens, 
                        notamment Roissy Charles de Gaulle et Orly. Que vous soyez en solo, en famille ou en groupe, nos options s’adaptent à tous vos besoins.
                        Profitez d’un service VTC aéroport premium pour Roissy-CDG ou Orly, avec des tarifs compétitifs à partir de 49 euros.
                    </p>
                    <div className="pricing_grid">
                        <div className="pricing_card">
                            <img src="/ClasseE.png" loading="lazy" alt="Berline confortable pour trajet vers les aéroports" />
                            <h3>Trajet en Berline</h3>
                            <p>Confort et élégance pour vos déplacements individuels ou professionnels.</p>
                            <strong>À partir de 49€</strong>
                        </div>
                        <div className="pricing_card">
                            <img src="/ClasseV.png" loading="lazy" alt="Van spacieux pour trajet vers les aéroports" />
                            <h3>Trajet en Van</h3>
                            <p>Idéal pour les familles ou les groupes avec un espace généreux pour les bagages.</p>
                            <strong>À partir de 69€</strong>
                        </div>
                        <div className="pricing_card">
                            <img src="/Maybach.png" loading="lazy" alt="Véhicule premium pour un trajet luxueux vers les aéroports" />
                            <h3>Trajet Premium</h3>
                            <p>Une expérience haut de gamme avec nos véhicules luxueux et un service personnalisé.</p>
                            <strong>À partir de 89€</strong>
                        </div>
                    </div>
                    <div className="pricing_advantages">
                        <h3>Pourquoi Choisir Nos Services pour Vos Trajets vers Roissy Charles de Gaulle et Orly ?</h3>
                        <ul>
                            <li><strong>Des chauffeurs professionnels</strong> pour un trajet sûr et agréable.</li>
                            <li><strong>Ponctualité garantie :</strong> arrivez à l’aéroport à l’heure, sans stress.</li>
                            <li><strong>Réservation simple et rapide :</strong> en ligne ou par téléphone.</li>
                            <li><strong>Véhicules haut de gamme :</strong> avec sièges confortables et options modernes (Wifi, chargeurs, etc.).</li>
                            <li><strong>Transparence des tarifs :</strong> pas de frais cachés, tout est clair dès la réservation.</li>
                        </ul>
                    </div>
                    <div className="pricing_cta">
                        <p>Réservez dès maintenant votre trajet vers l’aéroport de votre choix en toute simplicité.</p>
                        <div className="pricing_cta_button">
                            <Link to="/reservation">
                                <Button variant="soft">Réserver Maintenant</Button>
                            </Link>
                            <a href="tel:0650240998">
                                <Button variant="soft">Nous Contacter : 06.50.24.09.98</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="info_aeroport_section roissy_section" id="info_roissy">
                <div className="info_aeroport_container">
                  <h2 className="section_title">Informations Pratiques : Roissy-Charles de Gaulle (CDG)</h2>
                  <div className="info_aeroport_intro">
                    <img src="/roissyCDG.webp" loading="lazy" alt="Service VTC Terminal Roissy Charles de Gaulle transfert privé" className="info_aeroport_image" />
                    <p className="info_aeroport_text">
                      Situé à environ 25 km au nord-est de Paris, Roissy-CDG est le plus grand aéroport de France, desservant des destinations du monde entier. 
                      Nos services de VTC assurent des transferts rapides, confortables et fiables, adaptés à tous vos besoins.
                    </p>
                  </div>
                  <div className="info_aeroport_details">
                    <div className="info_card">
                      <FontAwesomeIcon icon={faBriefcase} className="info_icon" />
                      <h3>Terminals et Services</h3>
                      <p>
                        Roissy dispose de plusieurs terminaux modernes (1, 2A-2G, et 3) offrant des zones duty-free, restaurants et lounges pour les voyageurs.
                      </p>
                    </div>
                    <div className="info_card">
                      <FontAwesomeIcon icon={faClock} className="info_icon" />
                      <h3>Temps de Trajet en VTC</h3>
                      <p>Le trajet entre Paris et Roissy-CDG dure environ 45 à 60 minutes, selon le trafic.</p>
                    </div>
                    <div className="info_card">
                      <FontAwesomeIcon icon={faInfoCircle} className="info_icon" />
                      <h3>Contact</h3>
                      <p>
                        <strong>Adresse :</strong> 95700 Roissy-en-France<br />
                        <strong>Téléphone :</strong> +33 1 70 36 39 50<br />
                      </p>
                    </div>
                  </div>
                  <div className="cta_section">
                    <p>Profitez d’un transfert premium vers Roissy-Charles de Gaulle. Réservez dès maintenant avec Tarif-VTC.</p>
                    <Link to="/reservation">
                      <Button variant="soft" className="cta_button">Réserver un VTC pour Roissy</Button>
                    </Link>
                  </div>
                </div>
              </section>

              <section className="info_aeroport_section orly_section" id="info_orly">
                  <div className="info_aeroport_container">
                    <h2 className="section_title">Informations Pratiques : Paris-Orly (ORY)</h2>
                    <div className="info_aeroport_intro">
                      <img src="/orly_aeroport.webp" loading="lazy" alt="Service VTC Terminal Paris-Orly transfert privé" className="info_aeroport_image" />
                      <p className="info_aeroport_text">
                        À seulement 13 km au sud de Paris, l'aéroport d'Orly est idéal pour les vols nationaux et internationaux. 
                        Nos VTC assurent des transferts sans stress pour un départ ou une arrivée en toute tranquillité.
                      </p>
                    </div>
                    <div className="info_aeroport_details">
                      <div className="info_card">
                        <FontAwesomeIcon icon={faPlaneDeparture} className="info_icon" />
                        <h3>Terminals et Services</h3>
                        <p>
                          Orly est divisé en quatre zones principales : Orly 1, Orly 2, Orly 3 et Orly 4. Vous y trouverez de nombreux restaurants, boutiques et lounges.
                        </p>
                      </div>
                      <div className="info_card">
                        <FontAwesomeIcon icon={faClock} className="info_icon" />
                        <h3>Temps de Trajet en VTC</h3>
                        <p>Le trajet entre Paris et Orly dure environ 30 à 40 minutes.</p>
                      </div>
                      <div className="info_card">
                        <FontAwesomeIcon icon={faInfoCircle} className="info_icon" />
                        <h3>Contact</h3>
                        <p>
                          <strong>Adresse :</strong> 94390 Orly<br />
                          <strong>Téléphone :</strong> +33 1 70 36 39 50<br />
                        </p>
                      </div>
                    </div>
                    <div className="cta_section">
                      <p>Besoin d’un transfert fiable vers Paris-Orly ? Réservez dès maintenant avec Tarif-VTC.</p>
                      <Link to="/reservation">
                        <Button variant="soft" className="cta_button">Réserver un VTC pour Orly</Button>
                      </Link>
                    </div>
                  </div>
                </section>


            <section className="cars_list_section" id="cars_list">
              <div className="cars_list_container">
                <h2>Découvrez notre flotte de véhicules pour les Transfert vers et depuis les aéroports de Paris</h2>
                <p>Nous proposons une gamme de services adaptés pour vos trajets vers et depuis l’aéroport. Nos VTC pour aéroport sont conçus pour s’adapter aux besoins des voyageurs, qu’il s’agisse de départs anticipés ou d’arrivées tardives.</p>
               
                <div className="cars_list_cards">
                {carDataMap.map((carData, index) => (
                    <CarCard imgUrl={carData.img} alt={carData.alt} carType={carData.name} brand={carData.marque + ' ' + carData.model} passengerNb={carData.person} LuggageNb={carData.luggage} addLuggage={carData.additional_luggage} linkUrl={carData.linkUrl} key={index} />
                  ))}
                </div>
              </div>
            </section>

            <section className="eco_section" id="eco">
                <div className="eco_container">
                    <h2>Notre Engagement Écologique pour Vos Trajets vers les Aéroports</h2>
                    <p>
                        Chez Tarif-VTC, nous croyons qu’un service de qualité peut rimer avec respect de l’environnement. C’est pourquoi nous nous engageons 
                        à vous proposer des trajets écoresponsables vers les principaux aéroports parisiens, tels que Roissy Charles de Gaulle et Orly.
                    </p>
                    <ul>
                        <li>
                            <strong>Véhicules hybrides et électriques :</strong> Notre flotte intègre des modèles écologiques pour minimiser notre empreinte carbone, 
                            tout en garantissant un confort optimal.
                        </li>
                        <li>
                            <strong>Optimisation des trajets :</strong> Nous planifions intelligemment nos déplacements pour réduire les trajets à vide et ainsi limiter les émissions de CO2.
                        </li>
                        <li>
                            <strong>Réduction de l’empreinte écologique :</strong> Nous sensibilisons nos chauffeurs et passagers à adopter des pratiques respectueuses de l’environnement.
                        </li>
                    </ul>
                    <p>
                        En choisissant Tarif-VTC pour vos trajets vers les aéroports de Roissy et Orly, vous contribuez à un transport durable, sans compromettre votre confort ni votre sérénité. 
                        Ensemble, optons pour une mobilité plus verte, adaptée aux besoins d’aujourd’hui et de demain.
                    </p>
                </div>
            </section>


            <section className="car_inside_services_section" id="car_inside_services">
                <div className="car_inside_services_container">
                    <h2>Services à Bord : Profitez d’un Confort Optimal Pendant Votre Trajet</h2>
                    <p>
                        Nos véhicules sont équipés pour garantir une expérience de conduite haut de gamme. Que vous voyagiez en famille, entre amis ou pour des affaires, 
                        profitez de prestations adaptées à vos besoins pour un trajet agréable et serein.
                    </p>
                    <div className="car_inside_services_content">
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBaby} className="iconCustom" />
                            <p>Siège enfant : sécurité et confort pour les plus petits.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBottleWater} className="iconCustom" />
                            <p>Eau : bouteilles fraîches offertes pour votre hydratation.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom" />
                            <p>Chargeurs : rechargez vos appareils pendant le trajet.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faWifi} className="iconCustom" />
                            <p>Wifi / Bluetooth : restez connecté tout au long du voyage.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faCreditCard} className="iconCustom" />
                            <p>Paiement à bord : flexible et sécurisé pour votre tranquillité.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                <h2>FAQ - Tout Savoir sur Nos Services de VTC pour Transferts Aéroports</h2>
                <p>Vous avez des questions sur nos trajets vers les aéroports comme Roissy Charles de Gaulle (CDG) ou Orly (ORY) ? Découvrez toutes les réponses ici et profitez d’un service de VTC fiable, confortable et adapté à vos besoins.</p>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section> 

            <section className="contact_section" id="contact">
            <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez-nous pour Réserver Votre VTC ou Obtenir des Informations</h2>
                    <p>
                    Notre équipe est disponible 24h/24 et 7j/7 pour répondre à vos questions et organiser vos transferts vers les aéroports de Roissy et Orly. 
                    Réservez dès maintenant votre VTC pour un service premium, fiable et adapté à vos besoins.
                    </p>
                    <a href="tel:0650240998">
                        <Button variant="soft">Prendre un rendez-vous</Button>
                    </a>
                </div>
                <div className="contact_content_right">
                    <img 
                        src="/logo_white.webp" 
                        alt="Logo Tarif-VTC.fr pour le contact" 
                        width={300} 
                        height={300} 
                        loading="lazy"
                    />
                </div>
            </div>
        </section>




        </>
    );
  };
  export default ServiceAeroport;