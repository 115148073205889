import BusinessVan from "../../../data/businessVan.json";
import LuxuryCar from "../../../data/luxuryCar.json";
import BusinessCar from "../../../data/businessCar.json";

import '../NosServices.css';

import React, { useEffect, useState } from 'react'
import { Outlet, Link, useParams  } from "react-router-dom";
import CarCard from "../../../components/CarCard/CarCard";

import Button from '@mui/joy/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import GroupIcon from '@mui/icons-material/Group';
import LuggageIcon from '@mui/icons-material/Luggage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faLocationDot, faMoneyBill, faCar, faFacebookF } from '@fortawesome/free-solid-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
      question: 'Combien de temps dure le trajet en VTC entre Paris et Disneyland Paris ?',
      answer: "En fonction de la circulation, le trajet dure généralement environ 45 minutes depuis le centre de Paris jusqu'à Disneyland Paris.",
  },
  {
      question: "Proposez-vous des options de VTC adaptées pour les familles et les groupes ?",
      answer: "Oui, nous avons des véhicules spacieux adaptés aux familles et aux groupes. Vous pouvez réserver un véhicule avec suffisamment de places pour assurer un trajet confortable à tous les passagers.",
  },
  {
      question: "Peut-on réserver un siège pour enfant lors d'un trajet en VTC vers Disneyland Paris ?",
      answer: "Absolument ! Lors de la réservation, il vous suffit de demander un siège pour enfant, et notre chauffeur s'assurera qu'il soit prêt pour le trajet.",
  },
  {
      question: "Quels sont les moyens de paiement acceptés pour un VTC vers Disneyland Paris ?",
      answer: "Nous acceptons les paiements par carte bancaire, en ligne ou directement avec le chauffeur. Les options de paiement sont sécurisées pour assurer votre tranquillité d'esprit.",
  },
  {
      question: "Quels sont les avantages de réserver un VTC pour Disneyland Paris plutôt qu'un taxi ?",
      answer: "Nos VTC offrent un service premium avec des chauffeurs professionnels, des véhicules haut de gamme, et des prix fixes sans surprise. Contrairement aux taxis, nos services incluent des options comme des sièges pour enfants, du Wifi à bord et des options de réservation flexibles.",
  },
  {
      question: "Peut-on réserver un VTC pour Disneyland Paris à l'avance ?",
      answer: "Oui, vous pouvez réserver votre trajet à l'avance pour garantir la disponibilité et éviter tout stress le jour de votre départ. Vous pouvez choisir l'heure et le lieu de prise en charge qui vous conviennent le mieux.",
  },
  {
      question: "Quels types de véhicules proposez-vous pour un trajet en VTC vers Disneyland Paris ?",
      answer: "Nous proposons une large gamme de véhicules, des berlines élégantes pour les couples ou les petits groupes, aux vans spacieux pour les familles nombreuses ou les groupes. Des options premium sont également disponibles pour une expérience de luxe.",
  },
  {
      question: "Puis-je réserver un trajet aller-retour en VTC pour Disneyland Paris ?",
      answer: "Oui, nous offrons la possibilité de réserver des trajets aller-retour. Cela vous permet de profiter pleinement de votre journée à Disneyland Paris sans vous soucier de votre transport pour le retour.",
  },
  {
      question: "Le service de VTC fonctionne-t-il 24h/24 et 7j/7 pour Disneyland Paris ?",
      answer: "Oui, nos chauffeurs sont disponibles 24h/24 et 7j/7 pour vous emmener à Disneyland Paris ou vous ramener, quel que soit l'heure de votre trajet.",
  },
  {
      question: "Comment se passe la prise en charge pour un trajet en VTC vers Disneyland Paris ?",
      answer: "Votre chauffeur viendra vous chercher à l'endroit convenu, à l'heure exacte de votre réservation. Il vous aidera avec vos bagages si nécessaire et s'assurera que vous profitiez d'un trajet confortable et sans stress.",
  },
  {
      question: "Quels sont les tarifs pour un trajet en VTC vers Disneyland Paris ?",
      answer: "Nos tarifs commencent à partir de 29€ pour les trajets vers Disneyland Paris. Nous proposons des prix compétitifs et transparents sans frais cachés. Pour un devis précis, utilisez notre outil de réservation en ligne.",
  },
  {
      question: "Vos chauffeurs VTC connaissent-ils bien les itinéraires vers Disneyland Paris ?",
      answer: "Oui, nos chauffeurs sont expérimentés et connaissent parfaitement les itinéraires optimaux vers Disneyland Paris, ce qui vous garantit un trajet fluide et sans détour.",
  },
  {
      question: "Est-il possible de transporter des bagages volumineux dans vos VTC pour Disneyland Paris ?",
      answer: "Oui, nos véhicules disposent de suffisamment d'espace pour transporter vos bagages volumineux, que ce soit des valises, des poussettes ou d'autres équipements nécessaires pour votre visite à Disneyland Paris.",
  },
];


const carFiles = ['businessCar','businessVan','luxuryCar'];
const carDataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));

const VTCDisney = ({}) => {
    //const { carName } = useParams();
    const [carDataMap, setCarDataMap] = useState([]);
 

    useEffect(() => {
      window.scrollTo(0, 0);
  
      // Importez dynamiquement les fichiers JSON
      const dataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));
      setCarDataMap(dataMap);
  
      // Ajout des balises SEO
      document.title = "Réservez Votre VTC pour Disneyland Paris - Tarif-VTC.fr";
      const metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      metaDescription.content = 
          "Découvrez nos services de VTC premium pour Disneyland Paris. Profitez de tarifs compétitifs, d'un confort optimal, et d'un service client disponible 24h/24.";
      document.head.appendChild(metaDescription);
  
      return () => {
          // Nettoie les balises SEO pour éviter des duplications
          document.head.removeChild(metaDescription);
      };
    }, []); // Le tableau vide en tant que deuxième argument signifie que cela s'exécutera une seule fois au chargement du composant
  

    return (
        <div className="car_section">
          <div className="nos_voitures_section_contentHead" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(/vtc-disney-pluto.webp)`}}>
            <img src="/logo_white_crop.webp" loading="lazy" alt="tarif vtc logo"/>
            <h1 class="display-4 text-center  p-md-3"><span class="color1">Réservez Votre VTC pour Disneyland Paris</span> : Confort et Magie</h1>
            <p>Voyagez vers Disneyland Paris avec un chauffeur privé. Profitez d'un trajet serein et confortable à des tarifs compétitifs.</p>
          </div>

          <section className='head_title_section'>
              <div className='head_title_content'>
                <h2> Chauffeur privé en région parisienne</h2>
                <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide par <a href="tel:0650240998">téléphone</a></p>
                <div className='social_media_icons'>
                  <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" loading="lazy" alt="tarif vtc facebook"/></a>
                  <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" loading="lazy" alt="tarif-vtc.fr logo instagram"/></a>
                </div>
              </div>
              <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez </Button></Link><div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div></div>
            </section>  

            <section className="presentation_section" id="presentation">
              <div className="presentation_section_content">
                  <img 
                      src="/vtc-disney-mickey.webp" 
                      alt="VTC Disneyland Paris avec Mickey Mouse et Minnie dans une parade entourés de visiteurs"
                      loading="lazy"
                  />
                  <div className='presentation_text'>
                      <h2>Tarifs VTC pour Roissy, Orly, et Disneyland Paris : Voyagez en Toute Sérénité</h2>
                      <p>
                          Découvrez nos tarifs compétitifs pour vos trajets vers les aéroports de Roissy, Orly et Disneyland Paris. 
                          Nous vous garantissons un voyage confortable, sécurisé et ponctuel.
                      </p>
                      <p>
                          Que vous voyagiez seul, en famille ou entre amis, notre service de VTC vous transporte directement aux portes de la magie. 
                          Réservez dès aujourd'hui et profitez d'une expérience unique avec nos chauffeurs professionnels.
                      </p>
                  </div>
              </div>
          </section>

          <section className="reservation_link_section" id="reservation_link">
              <div className="reservation_link_content">
                  <div className="reservation_link_left">
                      <h2>Réservez Votre VTC pour Disneyland Paris en Toute Simplicité</h2>
                      <p>
                          Pour rendre votre voyage aussi agréable que possible, nous avons simplifié le processus de réservation. 
                          Quelques clics suffisent pour planifier votre trajet en toute sérénité.
                      </p>
                      <p>
                          Choisissez l'heure et le lieu de prise en charge qui vous conviennent. 
                          Nos options de paiement flexibles vous permettent de payer directement en ligne ou le jour même avec notre chauffeur. 
                          Une fois votre réservation confirmée, votre chauffeur privé s'occupera du reste, vous conduisant confortablement jusqu'à Disneyland Paris.
                      </p>
                  </div>
                  <div className="reservation-link">
                      <div className="reservation_link_right">
                          <Link to="/reservation">
                              <Button variant="soft">Réservez</Button>
                          </Link>
                      </div>
                      <div className="reservation_link_right">
                          <a href="tel:0650240998">
                              <Button variant="soft">06.50.24.09.98</Button>
                          </a>
                      </div>
                  </div>
              </div>
          </section>

          <section className="pricing_section" id="pricing">
              <div className="pricing_container">
                  <h2>Découvrez Nos Tarifs Compétitifs pour Votre Trajet vers Disneyland Paris</h2>
                  <p>
                      Avec Tarif-VTC, profitez d’un service de qualité à un prix abordable. Que vous voyagiez en solo, en famille ou en groupe, 
                      nous avons des options adaptées à tous vos besoins. Nos trajets commencent à partir de <strong>49 euros</strong>.
                  </p>
                  <div className="pricing_grid">
                      <div className="pricing_card">
                          <img src="/ClasseE.png" loading="lazy" alt="Berline confortable pour trajet Disneyland Paris" />
                          <h3>Trajet en Berline</h3>
                          <p>Confort et élégance pour vos déplacements individuels ou en couple.</p>
                          <strong>À partir de 49€</strong>
                      </div>
                      <div className="pricing_card">
                          <img src="/ClasseV.png" loading="lazy" alt="Van spacieux pour trajet Disneyland Paris" />
                          <h3>Trajet en Van</h3>
                          <p>Parfait pour les familles ou les groupes avec un espace généreux pour les bagages.</p>
                          <strong>À partir de 69€</strong>
                      </div>
                      <div className="pricing_card">
                          <img src="Maybach.png" loading="lazy" alt="Véhicule premium pour un trajet Disneyland Paris de luxe" />
                          <h3>Trajet Premium</h3>
                          <p>Une expérience de luxe avec nos véhicules haut de gamme et services personnalisés.</p>
                          <strong>À partir de 89€</strong>
                      </div>
                  </div>
                  <div className="pricing_advantages">
                      <h3>Pourquoi Choisir Nos Services ?</h3>
                      <ul>
                          <li><strong>Des chauffeurs professionnels</strong> pour un trajet sûr et agréable.</li>
                          <li><strong>Réservation simple et rapide</strong> en ligne ou par téléphone.</li>
                          <li><strong>Véhicules haut de gamme</strong> avec sièges confortables et options modernes (Wifi, chargeurs, etc.).</li>
                          <li><strong>Pas de frais cachés :</strong> nos tarifs sont transparents et compétitifs.</li>
                      </ul>
                  </div>
                  <div className="pricing_cta">
                      <p>Prêt à réserver votre trajet ? Contactez-nous ou réservez dès maintenant en ligne.</p>
                      <div className="pricing_cta_button">
                        <Link to="/reservation">
                            <Button variant="soft">Réserver Maintenant</Button>
                        </Link>
                        <a href="tel:0650240998">
                            <Button variant="soft">Nous Contacter : 06.50.24.09.98</Button>
                        </a>
                      </div>
                  </div>
              </div>
          </section>


          <section className="hotels_section" id="hotels">
              <div className="hotels_container">
                  <h2>Découvrez les Hôtels Disney à Disneyland Paris</h2>
                  <p>
                      Prolongez la magie de votre visite à Disneyland Paris en séjournant dans l’un des hôtels Disney. Chaque établissement 
                      propose une thématique unique, un confort inégalé et des avantages exclusifs pour rendre votre séjour encore plus inoubliable.
                  </p>
                  <div className="hotels_list">
                      <div className="hotel_item">
                          <img src="/Hotels_disney/disneyland_hotel.webp" loading="lazy" alt="Disneyland Hotel" />
                          <h3>Disneyland Hotel ★★★★★</h3>
                          <p>
                              Situé à l’entrée du Parc Disneyland, cet hôtel 5 étoiles vous plonge dans un univers royal et féérique, 
                              avec un accès direct aux attractions.
                          </p>
                      </div>
                      <div className="hotel_item">
                          <img src="/Hotels_disney/new_york.webp" loading="lazy" alt="Disney's Hotel New York - The Art of Marvel" />
                          <h3>Disney's Hotel New York – The Art of Marvel ★★★★</h3>
                          <p>
                              Découvrez l'univers Marvel dans ce décor new-yorkais sophistiqué, à seulement 10 minutes à pied des parcs.
                          </p>
                      </div>
                      <div className="hotel_item">
                          <img src="/Hotels_disney/bay_club.webp" loading="lazy" alt="Disney's Newport Bay Club" />
                          <h3>Disney's Newport Bay Club ★★★★</h3>
                          <p>
                              Inspiré des stations balnéaires de Nouvelle-Angleterre, cet hôtel offre une ambiance maritime élégante à 15 minutes des parcs.
                          </p>
                      </div>
                      <div className="hotel_item">
                          <img src="/Hotels_disney/sequoia.webp" loading="lazy" alt="Disney's Sequoia Lodge" />
                          <h3>Disney's Sequoia Lodge ★★★</h3>
                          <p>
                              Plongez dans une atmosphère chaleureuse et naturelle, entourée de séquoias, à 15 minutes à pied des parcs.
                          </p>
                      </div>
                      <div className="hotel_item">
                          <img src="/Hotels_disney/cheyenne.webp" loading="lazy" alt="Disney's Hotel Cheyenne" />
                          <h3>Disney's Hotel Cheyenne ★★</h3>
                          <p>
                              Vivez l'aventure du Far West dans cet hôtel au style western immersif, situé à 20 minutes à pied des parcs.
                          </p>
                      </div>
                      <div className="hotel_item">
                          <img src="/Hotels_disney/santa_fe.webp" loading="lazy" alt="Disney's Hotel Santa Fe" />
                          <h3>Disney's Hotel Santa Fe ★★</h3>
                          <p>
                              Voyagez sur la mythique Route 66 avec cet hôtel aux couleurs de "Cars", à 20 minutes des parcs.
                          </p>
                      </div>
                      <div className="hotel_item">
                          <img src="/Hotels_disney/davy_crockett_ranch.webp" loading="lazy" alt="Disney's Davy Crockett Ranch" />
                          <h3>Disney's Davy Crockett Ranch</h3>
                          <p>
                              Évadez-vous en pleine nature dans ce ranch proposant des bungalows, à seulement 15 minutes en voiture des parcs.
                          </p>
                      </div>
                      <div className="hotel_item">
                          <img src="/Hotels_disney/villages_nature_paris.webp" loading="lazy" alt="Villages Nature Paris" />
                          <h3>Villages Nature Paris</h3>
                          <p>
                              À 15 minutes en voiture des parcs, ce resort écoresponsable vous invite à une expérience axée sur la nature et le bien-être.
                          </p>
                      </div>
                  </div>
                  <p>
                      En séjournant dans un hôtel Disney, bénéficiez d’avantages exclusifs comme des Moments de Magie en Plus, vous permettant 
                      d’accéder aux parcs avant l’ouverture officielle. Pour plus d’informations, rendez-vous sur le site officiel des Hôtels Disney.
                  </p>
              </div>
          </section>



        <section className="presentation_section" id="presentation">
            <div className="presentation_section_content">
                <div className='presentation_text'>
                    <h2>Pourquoi Choisir Tarif-VTC pour Vos Voyages à Disneyland Paris ?</h2>
                    <p>
                        En choisissant Tarif-VTC, bénéficiez d'un service haut de gamme qui vous garantit confort, sérénité et une expérience de voyage exceptionnelle.
                        Évitez les tracas du stationnement et du trafic tout en profitant d'un trajet personnalisé adapté à vos besoins.
                    </p>
                    <ul>
                        <li><strong>Confort et espace :</strong> Voyagez en toute tranquillité avec nos véhicules adaptés aux familles et groupes.</li>
                        <li><strong>Service personnalisé :</strong> Prise en charge à votre domicile, sans détours inutiles, pour un trajet sur mesure.</li>
                        <li><strong>Gain de temps :</strong> Accédez directement à Disneyland Paris sans les inconvénients des transports en commun.</li>
                    </ul>
                </div>
                <img 
                    src="/vtc-disney-mini.webp" 
                    alt="Serre-tête Minnie Mouse avec le château de Disneyland Paris en arrière-plan et des visiteurs autour"
                    loading="lazy"
                />
            </div>
        </section>


        <section className="presentation_section" id="presentation">
            <div className="presentation_section_content">
                <div className='presentation_text'>
                    <h2>Destination Disneyland Paris : Conseils Pratiques pour une Journée Réussie</h2>
                    <p>
                        Profitez au maximum de votre visite à Disneyland Paris grâce à nos conseils pratiques. 
                        Que ce soit pour planifier vos attractions, éviter les files d'attente ou optimiser votre journée, 
                        nous avons rassemblé les meilleures astuces pour vous.
                    </p>
                    <ul className="conseils-list">
                        <li>
                            <strong>Arrivez tôt pour maximiser votre journée :</strong> Les portes de Disneyland Paris ouvrent généralement vers 9h30. 
                            Un départ matinal avec un VTC vous permet de profiter des attractions populaires avant l'arrivée des foules.
                        </li>
                        <li>
                            <strong>Planifiez les attractions à l'avance :</strong> Utilisez l'application mobile officielle de Disneyland pour suivre les temps d’attente des attractions en temps réel et optimiser votre parcours.
                        </li>
                        <li>
                            <strong>Préparez votre tenue en fonction de la météo :</strong> Prévoyez des vêtements adaptés à la saison. Pour l'été, pensez à emporter de la crème solaire et un chapeau. 
                            Nos VTC climatisés assurent votre confort, quelle que soit la météo.
                        </li>
                        <li>
                            <strong>Évitez les files d'attente aux repas :</strong> Réservez votre restaurant à l’avance via l’application Disneyland ou prévoyez des encas. 
                            Bien que les pique-niques ne soient pas autorisés à l’intérieur du parc, des espaces extérieurs sont disponibles.
                        </li>
                        <li>
                            <strong>Utilisez le service Fastpass :</strong> Profitez des Fastpass pour accéder rapidement aux attractions principales et éviter les longues attentes.
                        </li>
                    </ul>
                </div>
            </div>
        </section>


        <section className="cars_list_section" id="cars_list">
            <div className="cars_list_container">
                <h2>Découvrez Notre Flotte de Véhicules Haut de Gamme pour Disneyland Paris</h2>
                <p>
                    Notre flotte de véhicules est conçue pour répondre à tous vos besoins de transport, que ce soit pour un voyage en solo, en famille ou en groupe. 
                    Profitez d’un confort optimal, d’un espace généreux et de prestations haut de gamme pour rendre votre trajet agréable et mémorable.
                </p>
                <p>
                    Explorez notre gamme de véhicules, allant des berlines élégantes aux vans spacieux, parfaitement adaptés pour vos navettes depuis ou vers Disneyland Paris.
                </p>
                <div className="cars_list_cards">
                    {carDataMap.map((carData, index) => (
                        <CarCard 
                            imgUrl={carData.img} 
                            alt={`Véhicule ${carData.name} - ${carData.marque} ${carData.model}`} 
                            carType={carData.name} 
                            brand={carData.marque + ' ' + carData.model} 
                            passengerNb={carData.person} 
                            LuggageNb={carData.luggage} 
                            addLuggage={carData.additional_luggage} 
                            linkUrl={carData.linkUrl} 
                            key={index} 
                        />
                    ))}
                </div>
            </div>
        </section>

        <section className="eco_section" id="eco">
            <div className="eco_container">
                <h2>Notre Engagement Écologique pour Vos Trajets vers Disneyland Paris</h2>
                <p>
                    Chez Tarif-VTC, nous croyons que la magie de Disneyland Paris peut aussi rimer avec respect de l’environnement. 
                    C’est pourquoi nous nous engageons à offrir des trajets plus respectueux de la planète, tout en garantissant votre confort et votre sécurité.
                </p>
                <ul>
                    <li>
                        <strong>Véhicules hybrides et électriques :</strong> Notre flotte inclut des modèles hybrides et électriques pour réduire notre empreinte carbone.
                    </li>
                    <li>
                        <strong>Optimisation des trajets :</strong> Nous planifions intelligemment nos déplacements pour éviter les trajets à vide et minimiser les émissions de CO2.
                    </li>
                    <li>
                        <strong>Sensibilisation des passagers :</strong> Profitez de conseils sur des pratiques écoresponsables lors de votre visite à Disneyland Paris, 
                        comme le recyclage des déchets ou l’utilisation de contenants réutilisables.
                    </li>
                </ul>
                <p>
                    En choisissant Tarif-VTC pour vos trajets vers Disneyland Paris, vous contribuez à un tourisme plus durable, sans compromis sur votre expérience de voyage. 
                    Ensemble, faisons de chaque trajet un pas vers un avenir plus vert.
                </p>
            </div>
        </section>


        <section className="car_inside_services_section" id="car_inside_services">
            <div className="car_inside_services_container">
                <h2>Services à Bord : Profitez d’un Confort Optimal Pendant Votre Trajet</h2>
                <p>
                    Nos véhicules sont équipés pour garantir une expérience de conduite haut de gamme. Que vous voyagiez en famille, entre amis ou pour des affaires, 
                    profitez de prestations adaptées à vos besoins pour un trajet agréable et serein.
                </p>
                <div className="car_inside_services_content">
                    <div className="car_inside_services_item">
                        <FontAwesomeIcon icon={faBaby} className="iconCustom" />
                        <p>Siège enfant : sécurité et confort pour les plus petits.</p>
                    </div>
                    <div className="car_inside_services_item">
                        <FontAwesomeIcon icon={faBottleWater} className="iconCustom" />
                        <p>Eau : bouteilles fraîches offertes pour votre hydratation.</p>
                    </div>
                    <div className="car_inside_services_item">
                        <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom" />
                        <p>Chargeurs : rechargez vos appareils pendant le trajet.</p>
                    </div>
                    <div className="car_inside_services_item">
                        <FontAwesomeIcon icon={faWifi} className="iconCustom" />
                        <p>Wifi / Bluetooth : restez connecté tout au long du voyage.</p>
                    </div>
                    <div className="car_inside_services_item">
                        <FontAwesomeIcon icon={faCreditCard} className="iconCustom" />
                        <p>Paiement à bord : flexible et sécurisé pour votre tranquillité.</p>
                    </div>
                </div>
            </div>
        </section>


        <section className="Faq_section" id="FAQ">
            <div className="Faq_content">
                <div className="Faq_content_title">
                    <h2>FAQ - Tout ce que Vous Devez Savoir sur Nos VTC pour Disneyland Paris</h2>
                    <p>
                        Retrouvez ici les réponses aux questions les plus fréquemment posées sur nos services de VTC. 
                        De la réservation au paiement, découvrez comment nous simplifions vos trajets vers Disneyland Paris.
                    </p>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id={`panel-${index}-header`}
                            >
                                <Typography>{item.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{item.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}
            </div>
        </section>
   
        <section className="contact_section" id="contact">
            <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez-Nous pour Réserver Votre VTC vers Disneyland Paris</h2>
                    <p>
                        Vous avez une question ou souhaitez réserver votre trajet ? 
                        Notre équipe est disponible pour répondre à vos besoins et vous garantir une expérience de transport sans faille.
                    </p>
                    <a href="tel:0650240998">
                        <Button variant="soft">Prendre un rendez-vous</Button>
                    </a>
                </div>
                <div className="contact_content_right">
                    <img 
                        src="/logo_white.webp" 
                        alt="Logo Tarif-VTC.fr pour le contact" 
                        width={300} 
                        height={300} 
                        loading="lazy"
                    />
                </div>
            </div>
        </section>

        </div>
    );
};
export default VTCDisney;
