import { Outlet, Link } from "react-router-dom";
import Button from '@mui/joy/Button';
import React, { useEffect, useState } from 'react'
import CarCard from "../../../components/CarCard/CarCard";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faStar, faBriefcase, faShield, faGear, faLeaf, faRoad } from '@fortawesome/free-solid-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const carFiles = ['businessCar','businessVan','luxuryCar'];
//const carDataMap = carFiles.map((carFile) => require(`../../data/${carFile}.json`));
const carDataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));

const faqData = [
  {
      question: "Jusqu’à quelle distance puis-je réserver un VTC longue distance ?",
      answer: "Nos services de VTC longue distance couvrent des trajets en France et dans plusieurs pays européens. Que vous ayez besoin d'un transfert vers une autre ville, une station balnéaire, ou même une destination internationale, nous sommes à votre disposition pour répondre à vos besoins.",
  },
  {
      question: "Quels types de véhicules sont disponibles pour les trajets longue distance ?",
      answer: "Nous proposons une flotte de véhicules haut de gamme conçus pour le confort et la sécurité des longs trajets. Vous pouvez choisir parmi des berlines spacieuses, des vans adaptés pour les familles ou les groupes, et des options premium avec Wi-Fi, chargeurs, et climatisation pour un confort optimal.",
  },
  {
      question: "Comment réserver un VTC longue distance et quels sont les délais ?",
      answer: "La réservation se fait en ligne ou par téléphone. Il est recommandé de réserver au moins 24 à 48 heures à l'avance pour garantir la disponibilité et planifier au mieux votre trajet. Vous pouvez indiquer vos besoins spécifiques, comme des sièges pour enfants ou un itinéraire personnalisé.",
  },
  {
      question: "Les tarifs sont-ils fixes pour les trajets longue distance en VTC ?",
      answer: "Oui, nos tarifs sont fixes, transparents et communiqués à l'avance. Vous recevrez un devis détaillé lors de la réservation, incluant tous les frais (péages, carburant, etc.) pour éviter toute surprise. Planifiez votre voyage en toute sérénité.",
  },
  {
      question: "Puis-je voyager avec des bagages volumineux pour un trajet longue distance ?",
      answer: "Absolument ! Nos véhicules sont équipés pour accueillir des bagages volumineux. Lors de la réservation, précisez vos besoins pour que nous puissions vous attribuer le véhicule le plus adapté à votre trajet longue distance.",
  },
  {
      question: "Les trajets longue distance incluent-ils des pauses en cours de route ?",
      answer: "Oui, nos chauffeurs s’adaptent à vos besoins. Pour les trajets longue distance, vous pouvez demander des pauses régulières pour vous détendre, prendre un repas ou simplement faire une halte. Tout est fait pour rendre votre voyage agréable.",
  },
  {
      question: "Quels sont les avantages de choisir un VTC pour un trajet longue distance ?",
      answer: "Opter pour un VTC longue distance, c’est profiter d’un service personnalisé, d’un confort supérieur, et d’une flexibilité totale sur les horaires et les itinéraires. Contrairement aux transports en commun, vous bénéficiez d’un trajet porte-à-porte sans stress ni attente.",
  },
  {
      question: "Les services de VTC longue distance sont-ils disponibles 24h/24 et 7j/7 ?",
      answer: "Oui, nos services sont disponibles à toute heure, tous les jours de la semaine, pour répondre à vos besoins de déplacements longue distance. Que ce soit tôt le matin ou tard dans la nuit, nos chauffeurs sont prêts à vous accompagner.",
  },
];



const ServiceLonguesDistances = () => {

    const [carDataMap, setCarDataMap] = useState([]);

    useEffect(() => {
      window.scrollTo(0, 0);
  
      // Importez dynamiquement les fichiers JSON
      const dataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));
      setCarDataMap(dataMap);
  
      // SEO Meta Tags
      document.title = "VTC Longue Distance - Voyagez Confortablement à Travers la France et l'Europe | Tarif-VTC.fr";
      
      const metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      metaDescription.content = 
          "Réservez un VTC longue distance pour vos trajets en France ou en Europe. Profitez d'un service premium, de véhicules confortables et de chauffeurs expérimentés.";
      
      const metaKeywords = document.createElement("meta");
      metaKeywords.name = "keywords";
      metaKeywords.content = 
          "VTC longue distance, transport privé longue distance, chauffeur privé, trajet longue distance France, transfert longue distance Europe, réservation VTC";
  
      // Ajoutez les métadonnées dans le head
      document.head.appendChild(metaDescription);
      document.head.appendChild(metaKeywords);
  
      return () => {
          // Nettoie les balises SEO pour éviter des duplications
          document.head.removeChild(metaDescription);
          document.head.removeChild(metaKeywords);
      };
  }, []); // Le tableau vide en tant que deuxième argument signifie que cela s'exécutera une seule fois au chargement du composant
  
  
    return (
        <>
            <section className="service_section">
              <div className="service_longues_distance_section_contentHead">
                  <img src="/logo_white_crop.webp" alt="Logo Tarif-VTC, votre partenaire de confiance pour les trajets longue distance"/>
                  <h1>VTC Longue Distance : Voyagez Confortablement et en Toute Sécurité</h1>
                  <p>
                      Tarif-VTC vous propose des services de <strong>VTC longue distance</strong> adaptés à vos besoins. Que vous voyagiez pour affaires ou pour des loisirs, profitez d’un transport premium alliant confort, ponctualité et sécurité sur de longues distances.
                  </p>
              </div>


              <section className="head_title_section">
                <div className="head_title_content">
                    <h2>Votre Chauffeur Privé pour des Longues Distances</h2>
                    <p className="header_section_subText">
                        Service disponible 7j/7 et 24h/24. Réservez rapidement votre <strong>VTC longue distance</strong> pour voyager en toute sérénité. 
                    </p>
                    <div className="social_media_icons">
                        <a href="https://www.facebook.com/drivigo">
                            <img src="/facebook_logo.png" alt="Retrouvez Tarif-VTC sur Facebook"/>
                        </a>
                        <a href="https://www.instagram.com/mdprive_off/">
                            <img src="/instagram_logo.webp" alt="Suivez Tarif-VTC sur Instagram"/>
                        </a>
                    </div>
                </div>
                <div className="reservation_link">
                    <Link to="/reservation">
                        <Button variant="soft">Réservez votre trajet</Button>
                    </Link>
                    <div className="reservation_link_right">
                        <a href="tel:0650240998">
                            <Button variant="soft">06.50.24.09.98</Button>
                        </a>
                    </div>
                </div>
            </section>


                    <div className="service_content_deck">
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                            <p className="title">Sécurité</p>
                            <p>Votre sécurité est notre priorité absolue. Nous adhérons à des normes de sécurité strictes.</p>
                        </div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faLeaf} className="iconCustom"/>
                            <p className="title">Environnement</p>
                            <p>Voyagez avec nous en sachant que vous faites un choix respectueux de l&apos;environnement.</p>
                        </div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faRoad} className="iconCustom"/>
                            <p className="title">Flexibilité</p>
                            <p>Nous comprenons que vos horaires peuvent être variables et que vos exigences peuvent changer.</p>
                        </div>
                    </div>

                    <div className="service_content_informations">
                        <img src="/route.webp" alt="Vue du rétroviseur d'une voiture noire sur une route bordée d'arbres, symbolisant un trajet longue distance en toute sérénité" />
                        <div className="service_content_informations_text">
                            <h2>VTC Longue Distance : Votre Solution de Transport Personnalisé et Sécurisé</h2>
                            <p>
                                Optez pour un service de <strong>VTC longue distance</strong> qui combine confort, sécurité et ponctualité. Que ce soit pour un déplacement professionnel, une visite familiale ou une escapade touristique, 
                                nos chauffeurs privés expérimentés vous accompagnent sur des trajets longue distance dans des véhicules haut de gamme adaptés à vos besoins.
                            </p>
                            <p>
                                Profitez d’un transport personnalisé, pensé pour maximiser votre confort. Vous avez la possibilité de choisir parmi une gamme de véhicules spacieux et bien équipés, 
                                parfaits pour voyager seul, en famille ou en groupe. Avec <strong>Tarif-VTC</strong>, chaque trajet devient une expérience agréable, où la sécurité et la satisfaction du client sont notre priorité.
                            </p>
                        </div>
                    </div>

                    <div className="service_content_informations bottom_infos">
                        <div className="service_content_informations_text">
                            <h2>Les Avantages de Choisir un VTC pour les Longues Distances</h2>
                            <p>
                                Opter pour un <strong>VTC longue distance</strong> présente de nombreux avantages par rapport aux autres moyens de transport, qu'il s'agisse de la voiture personnelle, du train ou de l'avion. Voici pourquoi nos services répondent à vos attentes :
                            </p>
                            <ul>
                                <li><strong>Confort et luxe :</strong> Nos véhicules sont spacieux, climatisés et équipés de sièges ergonomiques pour un voyage relaxant. Profitez également de services à bord tels que Wi-Fi, chargeurs et boissons.</li>
                                <li><strong>Chauffeurs professionnels :</strong> Nos chauffeurs expérimentés veillent à votre sécurité et connaissent parfaitement les itinéraires pour garantir un trajet fluide et sans stress.</li>
                                <li><strong>Flexibilité totale :</strong> Avec nos services, vous choisissez vos horaires, votre itinéraire et vos arrêts, pour un trajet sur mesure, adapté à vos besoins spécifiques.</li>
                                <li><strong>Coût transparent :</strong> Contrairement aux transports en commun, nos tarifs sont fixés à l’avance, sans frais cachés. Vous savez exactement combien vous paierez.</li>
                            </ul>
                            <p>
                                Que vous souhaitiez voyager entre Paris et une autre région française ou vous rendre dans une capitale européenne, notre service de <strong>VTC longue distance</strong> est la solution idéale pour vos besoins de transport longue distance.
                            </p>
                        </div>
                        <img src="/route2.webp" alt="Une route de montagne entourée de nuages, symbolisant des trajets longue distance vers des destinations lointaines et pittoresques" />
                    </div>


            </section>

            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <h2>Réservez Votre VTC Longue Distance en Quelques Clics</h2>
                        <p>
                            Simplifiez vos déplacements longue distance grâce à notre processus de réservation rapide et intuitif. Que vous voyagiez pour des raisons professionnelles ou personnelles, suivez ces étapes pour planifier votre trajet en toute sérénité :
                        </p>
                        <ul>
                            <li>
                                <strong>1. Choisissez votre itinéraire :</strong> Indiquez votre point de départ, votre destination finale et l’heure de départ souhaitée pour un service personnalisé.
                            </li>
                            <li>
                                <strong>2. Configurez votre trajet :</strong> Spécifiez vos besoins (nombre de passagers, bagages supplémentaires, sièges enfants, etc.) et choisissez parmi nos véhicules premium pour garantir votre confort.
                            </li>
                            <li>
                                <strong>3. Réservez en ligne :</strong> Remplissez notre formulaire de réservation sécurisé en ligne et sélectionnez le mode de paiement qui vous convient. Vous recevrez une confirmation instantanée contenant tous les détails de votre trajet.
                            </li>
                        </ul>
                        <p>
                            Besoin d’aide ? Notre équipe dédiée est disponible 24h/24 et 7j/7 pour répondre à vos questions et vous accompagner dans l’organisation de votre voyage. Optez pour un service de **VTC longue distance** fiable et confortable dès aujourd’hui.
                        </p>
                    </div>
                    <div className="reservation-link">
                        <div className="reservation_link_right">
                            <Link to="/reservation">
                                <Button variant="soft">Réservez</Button>
                            </Link>
                        </div>
                        <div className="reservation_link_right">
                            <a href="tel:0650240998">
                                <Button variant="soft">06.50.24.09.98</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <section className="cars_list_section" id="cars_list">
                <div className="cars_list_container">
                    <h2>Notre Flotte de Véhicules : Confort et Excellence pour vos Longues Distances</h2>
                    <p>
                        Pour vos trajets longue distance, nous proposons une sélection de véhicules modernes et haut de gamme, conçus pour répondre à toutes vos attentes en matière de confort, de sécurité et de fiabilité. Que vous voyagiez seul, en famille ou en groupe, nos véhicules s'adaptent à vos besoins pour garantir une expérience de voyage sereine et agréable.
                    </p>
                    <ul>
                        <li>
                            <strong>Confort Premium :</strong> Sièges ergonomiques, espace généreux pour les jambes et climatisation réglable pour un trajet détendu.
                        </li>
                        <li>
                            <strong>Capacité adaptée :</strong> Des options pour accueillir plusieurs passagers et des volumes de bagages importants.
                        </li>
                        <li>
                            <strong>Équipements modernes :</strong> Chargeurs USB, connexion Wifi, et bouteilles d’eau fraîche pour rendre votre trajet encore plus agréable.
                        </li>
                    </ul>
                    <p>
                        Notre flotte comprend des berlines élégantes, des vans spacieux et des véhicules de luxe pour des trajets longue distance sur mesure. Faites votre choix et laissez-nous vous transporter dans un confort optimal.
                    </p>
                    <div className="cars_list_cards">
                        {carDataMap.map((carData, index) => (
                            <CarCard
                                imgUrl={carData.img}
                                alt={carData.alt}
                                carType={carData.name}
                                brand={carData.marque + ' ' + carData.model}
                                passengerNb={carData.person}
                                LuggageNb={carData.luggage}
                                addLuggage={carData.additional_luggage}
                                linkUrl={carData.linkUrl}
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </section>

            <section className="eco_section" id="eco">
                <div className="eco_container">
                    <h2>Notre Engagement Écologique pour vos Trajets Longue Distance</h2>
                    <p>
                        Chez Tarif-VTC, nous combinons confort et responsabilité environnementale pour vos trajets longue distance. Voyager loin ne signifie pas négliger l'impact écologique, et c’est pourquoi nous nous engageons à vous offrir des services écoresponsables sans compromis sur la qualité ou la sécurité.
                    </p>
                    <ul>
                        <li>
                            <strong>Véhicules hybrides et électriques :</strong> Nos modèles écologiques minimisent les émissions de CO2 tout en offrant un confort optimal pour les longs trajets.
                        </li>
                        <li>
                            <strong>Planification intelligente :</strong> Nous optimisons les itinéraires et limitons les trajets à vide pour réduire notre empreinte carbone.
                        </li>
                        <li>
                            <strong>Transport durable :</strong> Nos chauffeurs sont sensibilisés aux pratiques écoresponsables, et nous encourageons nos clients à opter pour des solutions respectueuses de l'environnement.
                        </li>
                    </ul>
                    <p>
                        Opter pour un **VTC longue distance écoresponsable** avec Tarif-VTC, c’est choisir un service de qualité qui respecte la planète tout en répondant à vos besoins de transport personnalisé. Ensemble, faisons le choix d’une mobilité durable, adaptée aux défis d’aujourd’hui et de demain.
                    </p>
                </div>
            </section>


            <section className="car_inside_services_section" id="car_inside_services">
                <div className="car_inside_services_container">
                    <h2>Services à Bord : Profitez d’un Confort Optimal Pendant Votre Trajet</h2>
                    <p>
                        Nos véhicules sont équipés pour garantir une expérience de conduite haut de gamme. Que vous voyagiez en famille, entre amis ou pour des affaires, 
                        profitez de prestations adaptées à vos besoins pour un trajet agréable et serein.
                    </p>
                    <div className="car_inside_services_content">
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBaby} className="iconCustom" />
                            <p>Siège enfant : sécurité et confort pour les plus petits.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBottleWater} className="iconCustom" />
                            <p>Eau : bouteilles fraîches offertes pour votre hydratation.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom" />
                            <p>Chargeurs : rechargez vos appareils pendant le trajet.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faWifi} className="iconCustom" />
                            <p>Wifi / Bluetooth : restez connecté tout au long du voyage.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faCreditCard} className="iconCustom" />
                            <p>Paiement à bord : flexible et sécurisé pour votre tranquillité.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                  <h3>FAQ - Service de VTC Longue Distance</h3>
                  <h4>Posez-nous Vos Questions</h4>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section>   

            <section className="contact_section" id="contact">
                <div className="contact_content">
                    <div className="contact_content_left">
                        <h2>Contactez-nous pour Réserver Votre VTC Longue Distance ou Poser vos Questions</h2>
                        <p>
                            Notre équipe est disponible <strong>24h/24 et 7j/7</strong> pour répondre à vos besoins. Que ce soit pour planifier un trajet longue distance, poser des questions sur nos services, ou obtenir un devis, nous sommes à votre écoute. 
                            Faites confiance à <strong>Tarif-VTC</strong> pour un service premium, fiable et parfaitement adapté à vos attentes.
                        </p>
                        <a href="tel:0650240998">
                            <Button variant="soft">Prendre un rendez-vous</Button>
                        </a>
                    </div>
                    <div className="contact_content_right">
                        <img 
                            src="/logo_white.webp" 
                            alt="Logo Tarif-VTC.fr pour le contact" 
                            width={300} 
                            height={300} 
                            loading="lazy"
                        />
                    </div>
                </div>
            </section>

        </>
    );
  };
  export default ServiceLonguesDistances;