import "../Services.css"
import Button from '@mui/joy/Button';
import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import CarCard from "../../../components/CarCard/CarCard";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faStar, faBriefcase, faShield, faGear, faLeaf, faRoad } from '@fortawesome/free-solid-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const carFiles = ['businessCar','businessVan','luxuryCar'];
const carDataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));

const faqData = [
    {
        question: 'Est-ce que le chauffeur attend si mon vol est retardé ?',
        answer: "Oui, nos chauffeurs surveillent en temps réel les horaires de vol grâce au suivi des vols en direct. En cas de retard, ils s'adaptent sans frais supplémentaires pour garantir votre prise en charge à votre arrivée.",
    },
    {
        question: "Peut-on réserver un VTC pour un groupe ?",
        answer: "Oui, nos véhicules spacieux, tels que des vans, peuvent accueillir jusqu'à 7 passagers avec leurs bagages. Que ce soit pour des voyages en famille, entre amis ou en équipe, nous avons la solution idéale pour un trajet confortable.",
    },
    {
        question: "Quels moyens de paiement sont acceptés ?",
        answer: "Nous acceptons les paiements par carte bancaire (Visa, MasterCard, etc.), en espèces directement auprès du chauffeur, ainsi que les paiements sécurisés en ligne lors de la réservation.",
    },
    {
        question: "Comment réserver un VTC pour Roissy-Charles de Gaulle ?",
        answer: "Pour réserver un VTC vers ou depuis Roissy-CDG, vous pouvez utiliser notre formulaire de réservation en ligne ou nous appeler directement au 06.50.24.09.98. Notre processus est rapide, sécurisé et adapté à vos besoins.",
    },
    {
        question: "Quels sont les avantages de choisir un VTC pour vos transferts aéroport ?",
        answer: "Avec un VTC, vous bénéficiez de tarifs fixes sans surprise, d'un confort premium avec des véhicules modernes, d'une prise en charge personnalisée, et de la tranquillité d'esprit en évitant le stress du trafic et du stationnement.",
    },
    {
        question: "Vos véhicules sont-ils équipés pour garantir un trajet confortable ?",
        answer: "Oui, nos véhicules sont équipés de sièges confortables, Wi-Fi, chargeurs pour appareils électroniques, climatisation, et bouteilles d'eau pour un trajet agréable et sans souci.",
    },
    {
        question: "Puis-je demander un siège auto pour enfant lors de la réservation ?",
        answer: "Absolument ! Lors de votre réservation, vous pouvez spécifier votre besoin de siège auto pour enfant. Nous veillons à la sécurité des plus jeunes passagers.",
    },
    {
        question: "Quels types de véhicules proposez-vous pour les trajets vers Roissy-CDG ?",
        answer: "Nous proposons une flotte variée comprenant des berlines élégantes pour les trajets individuels, des vans spacieux pour les groupes, et des véhicules premium pour une expérience luxueuse.",
    },
    {
        question: "Vos services sont-ils disponibles 24h/24 et 7j/7 ?",
        answer: "Oui, nous assurons des services de VTC disponibles 24h/24 et 7j/7 pour s'adapter à tous les horaires de vol, y compris les départs matinaux et les arrivées tardives.",
    },
    {
        question: "Comment annuler ou modifier ma réservation de VTC ?",
        answer: "Vous pouvez annuler ou modifier votre réservation en nous contactant directement par téléphone ou via notre service en ligne. Nous nous engageons à rendre le processus simple et sans tracas."
    },
];


const ServiceRoissy = () => {

    const [carDataMap, setCarDataMap] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    
        // Importez dynamiquement les fichiers JSON
        const dataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));
        setCarDataMap(dataMap);
    
        // SEO Meta Tags
        document.title = "VTC Roissy - Votre Transport Privé pour l'Aéroport Charles de Gaulle | Tarif-VTC.fr";
        
        const metaDescription = document.createElement("meta");
        metaDescription.name = "description";
        metaDescription.content = 
            "Réservez un VTC pour vos transferts vers l'aéroport de Roissy-Charles de Gaulle. Profitez d'un service premium et voyagez avec confort et sérénité.";
        
        const metaKeywords = document.createElement("meta");
        metaKeywords.name = "keywords";
        metaKeywords.content = 
            "VTC Roissy, transfert VTC Roissy, chauffeur privé Charles de Gaulle, transport Roissy CDG, réservation VTC Roissy, service premium VTC";
    
        // Ajoutez les métadonnées dans le head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
    
        return () => {
            // Nettoie les balises SEO pour éviter des duplications
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
        };
    }, []); // Le tableau vide en tant que deuxième argument signifie que cela s'exécutera une seule fois au chargement du composant
    

    return (
        <>
            <section className="service_section">

                <div className="service_aeroport_section_contentHead roissy">
                    <img src="/logo_white_crop.webp" loading="lazy" alt="tarif vtc logo"/>
                    <h1>VTC Roissy - Transferts Privés pour Roissy Charles de Gaulle</h1>
                </div>

                <section className='head_title_section'>
                    <div className='head_title_content'>
                        <h2> Votre Chauffeur Privé pour Roissy Charles de Gaulle </h2>
                        <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide par <a href="tel:0650240998">téléphone</a></p>
                        <div className='social_media_icons'>
                            <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" loading="lazy" alt="tarif vtc facebook"/></a>
                            <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" loading="lazy" alt="tarif-vtc.fr logo instagram"/></a>
                        </div>
                    </div>
                    <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez un transfert </Button></Link><div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div></div>
                </section> 

                <div className="service_content_deck">
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                        <p className="title">Chauffeurs professionnels</p>
                        <p>Des chauffeurs expérimentés pour vous accompagner lors de vos trajets vers Roissy.</p>
                    </div>
                    <div className="service_deck_separator"></div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faGear} className="iconCustom"/>
                        <p className="title">Flexibilité</p>
                        <p>Nous comprenons que vos horaires peuvent être variables et que vos exigences peuvent changer.</p>
                    </div>
                    <div className="service_deck_separator"></div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faRoad} className="iconCustom"/>
                        <p className="title">Porte à porte</p>
                        <p>De votre point de départ jusqu'à Roissy-CDG, pour un trajet sans interruption.</p>
                    </div>
                </div>

                <div className="service_content_informations">
                    <img src='/aeroport2.webp' loading="lazy" alt="Voiture de luxe blanche garée devant un avion gris et rouge dans un hangar moderne" />
                    <div className="service_content_informations_text">
                        <h2>Votre Partenaire pour les Transferts VTC vers l’Aéroport Roissy-Charles de Gaulle (CDG)</h2>
                        <p>
                            Tarif-VTC est votre solution idéale pour des transferts fiables et confortables vers l’aéroport **Roissy-CDG**, que ce soit pour un départ ou une arrivée. 
                            Nous comprenons l’importance de voyager sans stress, c’est pourquoi nous proposons un service adapté à vos besoins et horaires.
                        </p>
                        <h3>Pourquoi choisir nos services de VTC pour Roissy-CDG ?</h3>
                        <ul>
                            <li><strong>Prise en charge personnalisée :</strong> Nos chauffeurs vous accueillent directement à votre domicile, votre bureau ou à l'aéroport avec un panneau nominatif pour une prise en charge facilitée.</li>
                            <li><strong>Suivi des vols en temps réel :</strong> Nous surveillons vos horaires de vol pour garantir une arrivée ou une prise en charge à l’heure, même en cas de retard.</li>
                            <li><strong>Véhicules haut de gamme :</strong> Voyagez dans un confort optimal avec nos berlines, vans ou véhicules premium, tous équipés de Wi-Fi, chargeurs et autres commodités.</li>
                        </ul>
                        <h3>Nos engagements pour vos trajets vers Roissy :</h3>
                        <p>
                            Que vous soyez en voyage d’affaires, en vacances ou simplement à la recherche d’un transport fiable, nous nous engageons à rendre votre expérience fluide et agréable. 
                            Avec nos tarifs transparents et nos chauffeurs expérimentés, vous profitez d’un trajet sécurisé et sans mauvaise surprise.
                        </p>
                        <p>
                            Faites confiance à Tarif-VTC pour vos transferts vers Roissy et découvrez une nouvelle façon de voyager, alliant confort, ponctualité et excellence.
                        </p>
                    </div>
                </div>


                <div className="service_content_informations bottom_infos">
                    <div className="service_content_informations_text">
                        <h2>Pourquoi Choisir Tarif-VTC pour Vos Transferts vers l’Aéroport Roissy-Charles de Gaulle (CDG) ?</h2>
                        <p>
                            En choisissant nos services de **VTC vers Roissy-CDG**, vous bénéficiez d’une expérience de transport premium, pensée pour répondre aux attentes 
                            des voyageurs les plus exigeants. Que vous voyagiez pour affaires ou pour le plaisir, voici pourquoi nous sommes le choix idéal :
                        </p>
                        <ul>
                            <li>
                                <strong>Confort et espace :</strong> Nos véhicules offrent un intérieur spacieux, des sièges ergonomiques et des équipements modernes comme 
                                le Wi-Fi et des chargeurs, parfaits pour se détendre après un vol ou préparer une réunion.
                            </li>
                            <li>
                                <strong>Ponctualité et fiabilité :</strong> Nos chauffeurs professionnels suivent en temps réel vos horaires de vol pour garantir une prise 
                                en charge précise, qu’il s’agisse d’un départ ou d’une arrivée.
                            </li>
                            <li>
                                <strong>Tranquillité d’esprit :</strong> Fini le stress du parking et des embouteillages. Avec un chauffeur privé dédié, vous profitez d’un trajet fluide et sans souci.
                            </li>
                            <li>
                                <strong>Tarifs transparents :</strong> Avec Tarif-VTC, pas de surprises. Vous recevez un devis clair et détaillé lors de votre réservation, sans frais cachés.
                            </li>
                        </ul>
                        <h3>Une Expérience Sur-Mesure pour Chaque Voyageur</h3>
                        <p>
                            Que vous soyez seul, en famille ou en groupe, nous adaptons nos services à vos besoins spécifiques. Nos vans spacieux conviennent parfaitement aux grandes familles ou aux groupes de voyageurs, tandis que nos berlines premium apportent une touche d’élégance pour vos trajets professionnels.
                        </p>
                        <h3>Nos Engagements</h3>
                        <ul>
                            <li><strong>Sécurité maximale :</strong> Des chauffeurs expérimentés et des véhicules régulièrement entretenus pour un trajet en toute sérénité.</li>
                            <li><strong>Services personnalisés :</strong> Assistance avec vos bagages, sièges enfant disponibles sur demande et options pour les trajets de dernière minute.</li>
                            <li><strong>Disponibilité 24h/24 :</strong> Nous sommes là pour vous, quel que soit l’horaire de votre vol, y compris les départs matinaux et les arrivées tardives.</li>
                        </ul>
                    </div>
                    <img src='/vtc_gare.webp' loading="lazy" alt="Chauffeur privé ouvrant la porte pour une cliente en costume sortant d'une voiture de luxe Mercedes" />
                </div>

            </section>

            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <h2>Réserver un VTC pour Roissy-CDG : Simple, Rapide et Sécurisé</h2>
                        <p>
                            Planifiez dès aujourd'hui votre transfert en **VTC vers Roissy Charles de Gaulle (CDG)** avec Tarif-VTC. Que vous soyez en voyage d'affaires ou en vacances, notre système de réservation est conçu pour vous offrir une expérience fluide et sans stress.
                        </p>
                        <h3>Les étapes pour réserver un VTC vers Roissy :</h3>
                        <ul>
                            <li>
                                <strong>1. Sélectionnez votre itinéraire :</strong> Indiquez Roissy-CDG comme votre aéroport de départ ou d'arrivée et précisez l'heure souhaitée pour garantir une prise en charge optimale.
                            </li>
                            <li>
                                <strong>2. Configurez votre trajet :</strong> Personnalisez votre transfert en choisissant le type de véhicule adapté (berline, van ou premium), le nombre de passagers et le volume de bagages. Si besoin, ajoutez des options comme un siège enfant.
                            </li>
                            <li>
                                <strong>3. Réservez en ligne :</strong> Utilisez notre formulaire intuitif pour confirmer votre réservation en quelques clics. Vous recevrez une confirmation instantanée par email avec tous les détails de votre trajet.
                            </li>
                        </ul>
                        <h3>Nos avantages :</h3>
                        <ul>
                            <li>
                                <strong>Service disponible 24h/24 et 7j/7 :</strong> Nous nous adaptons à tous vos besoins, même pour les départs matinaux ou les arrivées tardives.
                            </li>
                            <li>
                                <strong>Suivi des vols en temps réel :</strong> Nos chauffeurs suivent vos horaires de vol pour ajuster leur arrivée en cas de retard ou d'avance.
                            </li>
                            <li>
                                <strong>Transparence des prix :</strong> Pas de frais cachés, vous connaissez le tarif à l'avance lors de la réservation.
                            </li>
                        </ul>
                    </div>
                    <div className="reservation-link">
                        <div className="reservation_link_right">
                            <Link to="/reservation">
                                <Button variant="soft">Réservez votre VTC en ligne</Button>
                            </Link>
                        </div>
                        <div className="reservation_link_right">
                            <a href="tel:0650240998">
                                <Button variant="soft">Appelez-nous : 06.50.24.09.98</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <section className="cars_list_section" id="cars_list">
                <div className="cars_list_container">
                    <h2>Notre Flotte de Véhicules pour Vos Transferts depuis et vers l’Aéroport Roissy-CDG</h2>
                    <p>
                    Pour vos trajets entre Paris et l’aéroport Roissy-Charles de Gaulle, notre flotte de véhicules premium s’adapte à toutes vos exigences. 
                    Que vous voyagiez seul, en famille ou en groupe, nous avons le véhicule idéal pour garantir un transfert confortable et fiable.
                    </p>
                    <ul>
                    <li><strong>Berlines élégantes :</strong> Idéales pour les trajets individuels ou professionnels, offrant un confort haut de gamme.</li>
                    <li><strong>Vans spacieux :</strong> Parfaits pour les familles ou les groupes, avec suffisamment d’espace pour les bagages volumineux.</li>
                    <li><strong>Véhicules premium :</strong> Une expérience luxueuse pour vos voyages d’affaires ou vos transferts VIP.</li>
                    </ul>
                    <p>
                    Nos véhicules sont équipés de Wi-Fi, de chargeurs et de sièges confortables pour que votre trajet vers Roissy-CDG soit une expérience relaxante, 
                    même pendant les heures de pointe.
                    </p>
                    <div className="cars_list_cards">
                    {carDataMap.map((carData, index) => (
                        <CarCard 
                        imgUrl={carData.img} 
                        alt={carData.alt} 
                        carType={carData.name} 
                        brand={carData.marque + ' ' + carData.model} 
                        passengerNb={carData.person} 
                        LuggageNb={carData.luggage} 
                        addLuggage={carData.additional_luggage} 
                        linkUrl={carData.linkUrl} 
                        key={index} 
                        />
                    ))}
                    </div>
                </div>
                </section>


            <section className="eco_section" id="eco">
            <div className="eco_container">
                <h2>Un Engagement Écologique pour Vos Transferts vers Roissy-CDG</h2>
                <p>
                Chez **Tarif-VTC**, nous avons à cœur de vous offrir des services de qualité tout en respectant l’environnement. 
                Nos trajets vers Roissy-Charles de Gaulle s’inscrivent dans une démarche durable et écoresponsable.
                </p>
                <ul>
                <li>
                    <strong>Flotte hybride et électrique :</strong> Nos véhicules incluent des modèles hybrides et électriques pour réduire les émissions de CO2 
                    tout en maintenant un niveau de confort optimal.
                </li>
                <li>
                    <strong>Planification optimisée :</strong> Nous réduisons les trajets à vide en optimisant nos itinéraires, limitant ainsi notre empreinte carbone.
                </li>
                <li>
                    <strong>Sensibilisation écologique :</strong> Nos chauffeurs sont formés pour adopter des pratiques responsables, et nous encourageons nos clients 
                    à utiliser des solutions durables pour leurs déplacements.
                </li>
                </ul>
                <p>
                En choisissant **Tarif-VTC** pour vos transferts vers Roissy-CDG, vous contribuez activement à une mobilité respectueuse de l’environnement, 
                sans compromis sur votre confort ou votre sérénité. Ensemble, faisons de chaque trajet un choix responsable.
                </p>
                <p>
                <strong>Besoin d’un trajet écoresponsable ?</strong> Réservez dès maintenant un transfert avec Tarif-VTC et profitez d’un service premium et durable.
                </p>
            </div>
            </section>

            <section className="car_inside_services_section" id="car_inside_services">
                <div className="car_inside_services_container">
                    <h2>Services à Bord : Profitez d’un Confort Optimal Pendant Votre Trajet</h2>
                    <p>
                        Nos véhicules sont équipés pour garantir une expérience de conduite haut de gamme. Que vous voyagiez en famille, entre amis ou pour des affaires, 
                        profitez de prestations adaptées à vos besoins pour un trajet agréable et serein.
                    </p>
                    <div className="car_inside_services_content">
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBaby} className="iconCustom" />
                            <p>Siège enfant : sécurité et confort pour les plus petits.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBottleWater} className="iconCustom" />
                            <p>Eau : bouteilles fraîches offertes pour votre hydratation.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom" />
                            <p>Chargeurs : rechargez vos appareils pendant le trajet.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faWifi} className="iconCustom" />
                            <p>Wifi / Bluetooth : restez connecté tout au long du voyage.</p>
                        </div>
                        <div className="car_inside_services_item">
                            <FontAwesomeIcon icon={faCreditCard} className="iconCustom" />
                            <p>Paiement à bord : flexible et sécurisé pour votre tranquillité.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                  <h3>FAQ - Service de VTC vers Roissy-CDG</h3>
                  <h4>Posez-nous Vos Questions</h4>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section> 

            <section className="contact_section" id="contact">
            <div className="contact_content">
                <div className="contact_content_left">
                <h2>Réservez Votre VTC pour Roissy et Orly - Contactez-nous 24h/24 et 7j/7</h2>
                <p>
                Notre équipe est à votre disposition jour et nuit pour répondre à vos questions et organiser vos transferts vers les aéroports de Roissy-Charles de Gaulle et Orly. 
                Profitez d’un service premium, ponctuel et personnalisé en réservant dès aujourd’hui votre VTC.
                </p>

                    <a href="tel:0650240998">
                        <Button variant="soft">Prendre un rendez-vous</Button>
                    </a>
                </div>
                <div className="contact_content_right">
                    <img 
                        src="/logo_white.webp" 
                        alt="Logo Tarif-VTC.fr pour le contact" 
                        width={300} 
                        height={300} 
                        loading="lazy"
                    />
                </div>
            </div>
        </section>
        </>
    );
};
export default ServiceRoissy;
