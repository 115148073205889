import './Home.css';
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, lazy, useState } from 'react'

import CarCard from "../../components/CarCard/CarCard";

import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faLocationDot, faMoneyBill, faCar, faFacebookF } from '@fortawesome/free-solid-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import Button from '@mui/joy/Button';

const faqData = [
  {
      question: 'Quelles sont les mesures prises pour assurer un transfert en toute sécurité depuis les aéroports et les gares ?',
      answer: "Nous prenons des mesures strictes en matière de sécurité, telles que des conducteurs agréés, des véhicules bien entretenus et des protocoles de désinfection réguliers.",
  },
  {
      question: "Comment fonctionne le service de déplacements longue distance en France ? Y a-t-il des arrêts prévus en cours de route ?",
      answer: "Notre service de déplacements longue distance vous offre un trajet direct sans arrêts prévus en cours de route, pour vous garantir une arrivée rapide à votre destination.",
  },
  {
      question: "Quelles sont les options de personnalisation disponibles pour les déplacements flexibles ?",
      answer: "Vous avez la possibilité de personnaliser votre trajet en choisissant l'heure de départ, la prise en charge à domicile et en bénéficiant de retards de paiement pour une flexibilité maximale.",
  },
  {
    question: "Quelle est la procédure pour demander l'installation d'un siège enfant dans le véhicule ?",
    answer: "Il vous suffit de mentionner votre demande lors de la réservation, et nous nous assurerons de fournir un siège enfant adapté à votre trajet.",
},
{
  question: "Comment fonctionne la connexion Wi-Fi à bord des véhicules ? Est-ce qu'elle est gratuite pour les passagers ?",
  answer: "Nous offrons une connexion Wi-Fi gratuite à bord de nos véhicules, afin que vous puissiez rester connecté(e) et profiter d'Internet pendant votre trajet.",
},
{
  question: "Est-ce que vous acceptez tous les types de cartes bancaires pour le paiement à bord ?",
  answer: "Oui, nous acceptons toutes les principales cartes bancaires, vous offrant ainsi la possibilité de payer à bord par carte de manière pratique et sécurisée.",
},
];

const PrestationPresentations = [
  {
    title: 'Transport VTC pour Disneyland Paris',
    imagesUrl: '/vtc-disney-mickey.webp',
    alt: "Personnages Disney incluant Mickey Mouse et Minnie dans une parade à Disneyland Paris, entourés de visiteurs prenant des photos",
    text: "Prêt(e) à vivre une journée inoubliable à Disneyland Paris ? Réservez votre VTC pour Disneyland Paris dès maintenant pour profiter d'un transport pratique et sans souci.",
    linkUrl: '/vtc-disney'
  },
  {
    title: 'VTC Aéroport avec Tarif-VTC : Votre Transport Privé et Confortable',
    imagesUrl: '/aéroport-départ.webp',
    alt: "Homme avec une valise attendant près d'une fenêtre à l'aéroport, prêt pour un départ en VTC vers sa destination",
    text: "Pour tous vos trajets vers l'aéroport, optez pour notre service de VTC aéroport : un transport confortable, fiable et adapté à vos horaires, pour un départ ou une arrivée en toute sérénité.",
    linkUrl: '/nos-services/aeroport'
  },
  {
    title: 'Déplacements longue distance en France avec Tarif-VTC.fr',
    imagesUrl: '/longues_distances.webp',
    alt: "chauffeur privé en costume noir portant des gants noir tenant le levier de vitesse d'une voiture vtc",
    text: 'Le choix idéal pour vos déplacements de longue distance en France. Nous offrons une flotte de véhicules haut de gamme pour assurer un voyage confortable et fiable.',
    linkUrl: '/nos-services/longues-distances'
  },
  {
    title: 'VTC Van avec Tarif-VTC : Confort et Espace pour 6 Passagers',
    imagesUrl: '/vtc-van.webp',
    alt: "Chauffeur VTC accueillant une famille pour un trajet en van de luxe Mercedes, idéal pour le transport de groupe",
    text: 'Besoin de transporter un groupe ou une famille en toute sérénité ? Découvrez notre service de VTC en van spacieux et confortable, idéal pour des trajets à plusieurs vers toutes vos destinations.',
    linkUrl: '/nos-services/vtc-van'
  },
  {
    title: 'VTC Paris avec Tarif-VTC : Votre Chauffeur Privé dans la Capitale',
    imagesUrl: '/paris-arc-de-triomphe.webp',
    alt: "Arc de Triomphe au coucher de soleil avec circulation sur les Champs-Élysées à Paris",
    text: 'Découvrez notre service de VTC à Paris, la solution idéale pour des déplacements confortables et rapides dans la capitale. Profitez d’un chauffeur privé disponible 24h/24 pour tous vos trajets professionnels, touristiques ou personnels à Paris et en Île-de-France.',
    linkUrl: '/nos-services/vtc-paris'
  },
  {
    title: 'Mise à disposition de Chauffeur Privé pour Vos Besoins',
    imagesUrl: '/mise_a_dispo.webp',
    alt: "client lisant un journal dans une voiture intérieur noir avec un chauffeur portant un costume",
    text: 'Nous proposons un service de mise à disposition de chauffeurs à l\'heure pour des déplacements urbains ou des excursions d\'une journée.',
    linkUrl: '/nos-services/mise-a-dispo'
  },
];

// Lazy load Autocomplete
const LazyAutocomplete = lazy(() => import('@react-google-maps/api').then(module => ({ default: module.Autocomplete })));

const carFiles = ['businessCar','businessVan','luxuryCar'];
const libraries = ['places'];


export default function Home() {
  const [carDataMap, setCarDataMap] = useState([]);

  const [preFormData, setPreFormData] = useState({
    adresse_start: '',
    adresse_end: '',
    date: new Date().toISOString().split('T')[0],
    departure_time: '11:00',
  });

  const [autocompleteStart, setAutocompleteStart] = useState(null);
  const [autocompleteEnd, setAutocompleteEnd] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBDZl1s5PQ6TREMrz-OmiXLu2dDtIGkm8Q',
    libraries,
  });


  useEffect(() => {
    window.scrollTo(0, 0);

    // Importez dynamiquement les fichiers JSON
    const dataMap = carFiles.map((carFile) => require(`../../data/${carFile}.json`));
    setCarDataMap(dataMap);

    // SEO Meta Tags
    document.title = "Tarif VTC - Votre Chauffeur Privé à Prix Compétitifs | Tarif-VTC.fr";

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content = 
        "Découvrez les tarifs VTC les plus compétitifs pour vos déplacements en région parisienne et en France. Réservez votre chauffeur privé dès maintenant avec Tarif-VTC.fr.";

    const metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content = 
        "tarif VTC, chauffeur privé, réservation VTC, prix VTC, VTC région parisienne, transport privé, VTC pas cher, service premium VTC";

    // Ajoutez les métadonnées dans le head
    document.head.appendChild(metaDescription);
    document.head.appendChild(metaKeywords);

    return () => {
        // Nettoie les balises SEO pour éviter des duplications
        document.head.removeChild(metaDescription);
        document.head.removeChild(metaKeywords);
    };
}, []); // Le tableau vide en tant que deuxième argument signifie que cela s'exécutera une seule fois au chargement du composant



  const navigate = useNavigate();

  const handlePlaceChanged = (field, autocomplete) => () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
  
      let streetNumber = '';
      let streetName = '';
      let city = '';
      let postalCode = '';
      let country = '';
  
      addressComponents.forEach((component) => {
        if (component.types.includes('street_number')) {
          streetNumber = component.long_name;
        }
        if (component.types.includes('route')) {
          streetName = component.long_name;
        }
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        }
        if (component.types.includes('country')) {
          country = component.long_name;
        }
      });
  
      const formattedAddress = `${streetNumber} ${streetName}, ${postalCode} ${city}, ${country}`;
  
      setPreFormData((prevData) => ({
        ...prevData,
        [field]: formattedAddress,
      }));
    }
  };
  
  const onLoadStart = (autocompleteInstance) => {
    setAutocompleteStart(autocompleteInstance);
  };
  
  const onLoadEnd = (autocompleteInstance) => {
    setAutocompleteEnd(autocompleteInstance);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPreFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams(preFormData).toString();
    //navigate('/reservation', { state: preFormData });
    navigate(`/Reservation?${queryParams}`);
  };

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

    return (
      <>

        <main className="main">

          <section className="header_section" id="header_section">
            <div className="header_section_content">

                <div className="title_content">
                  <img src="/logo_white_header.webp" loading="lazy" alt="tarif vtc logo"/>
                  <h1>Votre Service VTC Premium au Meilleur Tarif - Tarif-VTC.fr</h1>
                </div>


            {isLoaded ? (
            <form onSubmit={handleSubmit} className='Pre_form'>
              <h2> Réserver un chauffeur VTC maintenant </h2>
              <div className='PreFormLocationDeparture'>
                <label>Adresse de départ:</label>
                <LazyAutocomplete onLoad={onLoadStart} onPlaceChanged={handlePlaceChanged('adresse_start', autocompleteStart)}>
                  <input
                    type="text"
                    name="adresse_start"
                    value={preFormData.adresse_start}
                    onChange={handleChange}
                    required
                  />
                </LazyAutocomplete>
              </div>
              <div>
                <label>Adresse d'arrivée:</label>
                <LazyAutocomplete onLoad={onLoadEnd} onPlaceChanged={handlePlaceChanged('adresse_end', autocompleteEnd)}>
                  <input
                    type="text"
                    name="adresse_end"
                    value={preFormData.adresse_end}
                    onChange={handleChange}
                    required
                  />
                </LazyAutocomplete>
              </div>
              <div>
                <label>Date de départ:</label>
                <input
                  type="date"
                  name="date"
                  value={preFormData.date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label>Heure de départ:</label>
                <input
                  type="time"
                  name="departure_time"
                  value={preFormData.departure_time}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit">Réserver</button>
            </form>
          ) : (
            <div>Loading...</div>
          )}


        </div>







          </section>

          <section className="body_section">

            <section className='head_title_section'>
              <div className='head_title_content'>
                <h2> Chauffeur privé en région parisienne</h2>
                <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide par <a href="tel:0650240998">téléphone</a></p>
                <div className='social_media_icons'>
                  <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" loading="lazy" alt="tarif vtc facebook"/></a>
                  <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" loading="lazy" alt="tarif-vtc.fr logo instagram"/></a>
                </div>
              </div>
              <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez </Button></Link><Link to='/montrajet'><Button variant="soft"> Retrouver mon trajet </Button></Link></div>
            </section>         

            <section className="presentation_section" id="presentation">
                <div className="presentation_section_content">
                    <img src="/chauffeur-elegant.webp" loading="lazy"
                        alt="Chauffeur privé en costume élégant ouvrant la portière d'une Mercedes noire pour un client" 
                        className="presentation_image"/>
                    <div className="presentation_text">
                        <h2>Découvrez nos Tarifs VTC pour Roissy, Orly, Disneyland Paris et au-delà</h2>
                        <p>
                            Bienvenue chez <b>Tarif-VTC</b>, votre partenaire de confiance pour des <b>services de transport haut de gamme</b>. 
                            Que vous ayez besoin d’un <b>chauffeur privé</b> pour un transfert vers l’aéroport de Roissy ou Orly, une journée magique à Disneyland Paris, 
                            ou encore un trajet longue distance, nous sommes là pour répondre à vos attentes avec professionnalisme et ponctualité.
                        </p>
                        <p>
                            Avec <b>Tarif-VTC</b>, profitez de véhicules confortables, de chauffeurs expérimentés, et de tarifs transparents pour des 
                            <b>déplacements adaptés à vos besoins</b>, qu’ils soient professionnels ou personnels. Votre satisfaction est notre priorité.
                        </p>
                    </div>
                </div>
            </section>


            <section className="nos_services_section" id="nos_services">
              <div className="nos_services_container">
                <div className='nos_services_content'>
                  <h2> NOS SERVICES DE TRANSPORT VTC </h2>
                  <div className='nos_services_item_content'>
                    {PrestationPresentations.map((item, index) => (
                      <div className="nos_services_item" key={item.title + index}>
                        <img src={item.imagesUrl} loading="lazy" alt={item.alt}/>
                        <div className="nos_services_item_content">
                          <h3>{item.title}</h3>
                          <p>{item.text}</p>
                          <Link to={item.linkUrl}>Découvrir</Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>

            <section className="reserver_son_trajet_section" id="reserver_son_trajet">
                <div className="reserver_son_trajet_container">
                  <h2>Comment Réserver Votre Trajet avec Tarif-VTC.fr ?</h2>
                  <p>
                    Réserver votre chauffeur VTC avec Tarif-VTC.fr est un jeu d’enfant. Suivez ces étapes simples et profitez d’un transport premium, ponctuel et entièrement personnalisé.
                  </p>

                  <div className="reserver_son_trajet_steps">
                    <Card sx={{ maxWidth: 500 }} className="card_travel">
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="250"
                          image="/map.webp"
                          alt="Carte Google Maps avec une épingle rouge indiquant un chemin en jaune"
                          width="500px"
                          loading="lazy"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h4" component="div">
                            Sélectionnez votre trajet
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Indiquez votre point de départ et d'arrivée, ainsi que la date et l'heure souhaitées. Que ce soit pour un transfert aéroport ou un trajet longue distance, nous adaptons le service à vos besoins.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>

                    <Card sx={{ maxWidth: 500 }} className="card_travel">
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="250"
                          image="/phone.webp"
                          alt="Mains tenant un téléphone et une carte de crédit rouge au-dessus d'une table"
                          width="500px"
                          loading="lazy"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h4" component="div">
                            Réglez en toute simplicité
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Choisissez votre méthode de paiement préférée, que ce soit en ligne via notre site sécurisé ou directement à bord du véhicule. Nos solutions de paiement sont rapides et fiables.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>

                    <Card sx={{ maxWidth: 500 }} className="card_travel">
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="250"
                          image="/paiement.webp"
                          alt="Personne validant un achat en ligne via un smartphone"
                          width="500px"
                          loading="lazy"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h4" component="div">
                            Détendez-vous
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Une fois votre réservation confirmée, il ne vous reste plus qu'à vous relaxer. Nos chauffeurs professionnels s'occupent de tout pour vous offrir une expérience de voyage agréable et sécurisée.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </div>
                </div>

                <section className="reservation_link_section" id="reservation_link">
                    <div className="reservation_link_content">
                      <div className="reservation_link_left">
                        <h2>Réservez Votre Chauffeur Privé avec Tarif-VTC</h2>
                        <p>
                          Découvrez une expérience de transport premium adaptée à tous vos besoins. Que ce soit pour un transfert vers l’aéroport, un trajet longue distance ou une simple course en ville, profitez d’un service rapide, fiable et personnalisé.
                        </p>
                        <ul>
                          <li>
                            <strong>1. Indiquez vos besoins :</strong> Sélectionnez votre point de départ, destination et horaire pour garantir une prise en charge optimale.
                          </li>
                          <li>
                            <strong>2. Choisissez votre confort :</strong> Spécifiez le nombre de passagers, vos bagages et optez pour des services additionnels tels que Wi-Fi ou siège enfant.
                          </li>
                          <li>
                            <strong>3. Réservez en quelques clics :</strong> Complétez notre formulaire sécurisé en ligne et recevez une confirmation instantanée avec tous les détails de votre trajet.
                          </li>
                        </ul>
                        <p>
                          Besoin d’assistance ? Notre équipe est disponible 24h/24 et 7j/7 pour répondre à vos questions et vous accompagner dans la réservation de votre trajet.
                        </p>
                      </div>
                      <div className="reservation-link">
                        <div className="reservation_link_right">
                          <Link to="/reservation">
                            <Button variant="soft">Réservez Maintenant</Button>
                          </Link>
                        </div>
                        <div className="reservation_link_right">
                          <a href="tel:0650240998">
                            <Button variant="soft">06.50.24.09.98</Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </section>

              </section>


              <section className="cars_list_section" id="cars_list">
                  <div className="cars_list_container">
                    <h2>Notre Flotte de Véhicules Premium pour Tous Vos Besoins</h2>
                    <p>
                      Chez <strong>Tarif-VTC</strong>, nous proposons une sélection de véhicules haut de gamme conçus pour répondre à toutes vos attentes en matière de confort, de sécurité et de style. Que ce soit pour un transfert aéroport, un déplacement professionnel, ou un trajet longue distance, notre flotte est à votre disposition pour vous garantir une expérience de voyage exceptionnelle.
                    </p>
                    <div className="cars_list_cards">
                      {carDataMap.map((carData, index) => (
                        <CarCard
                          imgUrl={carData.img}
                          alt={carData.alt}
                          carType={carData.name}
                          brand={`${carData.marque} ${carData.model}`}
                          passengerNb={carData.person}
                          LuggageNb={carData.luggage}
                          addLuggage={carData.additional_luggage}
                          linkUrl={carData.linkUrl}
                          key={index}
                        />
                      ))}
                    </div>
                    <div className="cars_list_details">
                      <p>
                        Chaque véhicule de notre flotte est soigneusement sélectionné pour offrir :
                      </p>
                      <ul>
                        <li>
                          <strong>Un confort supérieur :</strong> sièges ergonomiques, climatisation, et un intérieur spacieux.
                        </li>
                        <li>
                          <strong>Une connectivité moderne :</strong> Wi-Fi à bord, prises USB, et options Bluetooth pour rester connecté.
                        </li>
                        <li>
                          <strong>Une capacité adaptée :</strong> idéale pour les familles, les groupes, ou les voyageurs avec de nombreux bagages.
                        </li>
                      </ul>
                      <p>
                        Faites l’expérience du luxe et de la sérénité avec notre service de VTC premium. Réservez dès maintenant votre trajet et choisissez le véhicule parfait pour votre voyage.
                      </p>
                    </div>
                  </div>
                </section>

            <section className="eco_section" id="eco">
                  <div className="eco_container">
                    <h2>Voyagez Écoresponsable avec Tarif-VTC</h2>
                    <p>
                      Chez <strong>Tarif-VTC</strong>, nous combinons luxe, confort et responsabilité environnementale pour chaque trajet. En choisissant notre service, vous contribuez à une mobilité durable tout en profitant d’une expérience de transport haut de gamme.
                    </p>
                    <ul>
                      <li>
                        <strong>Véhicules hybrides et électriques :</strong> Une flotte moderne qui réduit significativement les émissions de CO2 tout en garantissant un confort inégalé.
                      </li>
                      <li>
                        <strong>Optimisation des itinéraires :</strong> Nous planifions chaque trajet pour minimiser les kilomètres inutiles, réduisant ainsi notre impact écologique.
                      </li>
                      <li>
                        <strong>Engagement pour la durabilité :</strong> Nos chauffeurs sont formés aux pratiques écoresponsables, et nous encourageons nos clients à choisir des solutions respectueuses de l'environnement.
                      </li>
                    </ul>
                    <p>
                      Faire le choix de **Tarif-VTC**, c’est s’engager pour un transport de qualité, en phase avec les défis environnementaux actuels. Rejoignez-nous pour une mobilité plus verte, sans compromis sur le confort ou la fiabilité.
                    </p>
                    <div className="eco_cta">
                    <Link to="/eco"> Découvrire l'offre </Link>
                    </div>
                  </div>
                </section>
                 
                <section className="car_inside_services_section" id="car_inside_services">
                  <div className="car_inside_services_container">
                    <h2>Services à Bord : Voyagez dans un Confort Inégalé</h2>
                    <p>
                      Avec <strong>Tarif-VTC</strong>, chaque trajet devient une expérience premium. Nos véhicules sont minutieusement équipés pour répondre à toutes vos attentes, 
                      que vous voyagiez en famille, entre collègues ou pour des occasions spéciales. Nous mettons tout en œuvre pour vous offrir confort, sécurité et praticité.
                    </p>
                    <div className="car_inside_services_content">
                      <div className="car_inside_services_item">
                        <FontAwesomeIcon icon={faBaby} className="iconCustom" />
                        <p><strong>Siège enfant :</strong> Garantissez la sécurité et le confort des plus jeunes passagers.</p>
                      </div>
                      <div className="car_inside_services_item">
                        <FontAwesomeIcon icon={faBottleWater} className="iconCustom" />
                        <p><strong>Eau :</strong> Des bouteilles d'eau fraîche à disposition pour une hydratation optimale.</p>
                      </div>
                      <div className="car_inside_services_item">
                        <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom" />
                        <p><strong>Chargeurs :</strong> Rechargez vos appareils électroniques sans tracas pendant le trajet.</p>
                      </div>
                      <div className="car_inside_services_item">
                        <FontAwesomeIcon icon={faWifi} className="iconCustom" />
                        <p><strong>Wifi et Bluetooth :</strong> Restez connecté tout au long de votre voyage.</p>
                      </div>
                      <div className="car_inside_services_item">
                        <FontAwesomeIcon icon={faCreditCard} className="iconCustom" />
                        <p><strong>Paiement à bord :</strong> Des options flexibles et sécurisées pour votre tranquillité.</p>
                      </div>
                    </div>
                    <p>
                      Que vous partiez pour un court trajet en ville ou une longue distance, notre service à bord fait de votre confort une priorité. 
                      Découvrez la différence <strong>Tarif-VTC</strong>, où chaque détail compte pour une expérience exceptionnelle.
                    </p>
                    <div className="car_inside_services_cta">
                      <Link to="/reservation">
                        <Button variant="soft">Réservez votre trajet maintenant</Button>
                      </Link>
                    </div>
                  </div>
                </section>


            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                  <h3>Besoin de Plus de Détails ?</h3>
                  <h4>Posez-nous Vos Questions</h4>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section>

              <section className="contact_section" id="contact">
                <div className="contact_content">
                  <div className="contact_content_left">
                    <h2>Contactez-nous : Votre Trajet Premium Commence Ici</h2>
                    <p>
                      Chez <strong>Tarif-VTC</strong>, nous sommes disponibles <strong>24h/24 et 7j/7</strong> pour répondre à toutes vos demandes. 
                      Que vous souhaitiez planifier un trajet, poser des questions sur nos services, ou obtenir un devis personnalisé, notre équipe se tient à votre disposition pour vous offrir une expérience fluide et agréable.
                    </p>
                    <p>
                      Faites confiance à nos chauffeurs professionnels et à notre flotte premium pour des déplacements sur mesure, que ce soit pour un <strong>trajet local</strong>, un <strong>transfert aéroport</strong>, ou une <strong>longue distance</strong>.
                    </p>
                    <a href="tel:0650240998">
                      <Button variant="soft">Prendre un rendez-vous</Button>
                    </a>
                  </div>
                  <div className="contact_content_right">
                    <img 
                      loading="lazy"
                      src="/logo_white.webp" 
                      alt="Logo Tarif-VTC.fr pour le contact" 
                      width={300} 
                      height={300} 
                    />
                  </div>
                </div>
              </section>


          </section>

        </main>

      </>
  )
}


/*             



                  {PrestationSteps.map((item, index) => (
                        <div className="prestationSectionStep" key={item.title + index}>
                          <div className='prestationSectionIcon_title'>
                            <FontAwesomeIcon icon={item.icon} />
                            <div>{item.title}</div>
                          </div>
                          <p>{item.text}</p>
                        </div>
                    ))}


            <section className="bottomContactSection" id="contact">
              <div className="bottomContactSectionContent">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <p className="bottomContactPhoneNb">06 50 24 09 98</p>
                    <div className="bottomContactPhoneDivide"></div>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
              </div>
            </section>







<section className="adverstisingSection">
              <div className="adverstisingSectionContent">
                <div className="adverstisingSectionContentLeft">
                    <h2>Partenaire de tous vos déplacements au quotidien.</h2>
                    <p>Tarif-VTC.fr service de chauffeur privée en région parisienne</p>
                </div>
                <div className="adverstisingSectionContentRight">
                  <img src="/logo_white.png" alt="tarif vtc logo" width={300} height={300}/>
                </div>
              </div>
            </section>

            */