import React, { useState, useEffect } from "react";
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import "./Step1.css";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const steps = [
  'Itinéraire',
  'Options',
  'Passager',
  'Paiement',
];

const libraries = ['places'];

export default function Step1({ onSubmit, formData, setFormData, handleChange }) {
  const [autocompleteStart, setAutocompleteStart] = useState(null);
  const [autocompleteEnd, setAutocompleteEnd] = useState(null);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
      setFormData((prev) => ({
        ...prev,
        departure_time: getDefaultTime(),
      }));
  }, []); // Exécute au montage du composant uniquement

  const getDefaultTime = () => {
    const now = new Date();
    const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000); // Ajouter 1 heure
    const hours = String(oneHourLater.getHours()).padStart(2, "0");
    const minutes = String(oneHourLater.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const validateForm = () => {
    const newErrors = {};
  
    // Valider les champs obligatoires
    if (!formData.adresse_start) {
      newErrors.adresse_start = "L'adresse de départ est requise.";
    }
  
    if (formData.type_travel === "simple journey" && !formData.adresse_end) {
      newErrors.adresse_end = "L'adresse d'arrivée est requise.";
    }
  
    if (!formData.date) {
      newErrors.date = "La date est requise.";
    }
  
    if (!formData.departure_time) {
      newErrors.departure_time = "L'horaire de départ est requis.";
    }
  
    // Retourner les erreurs
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // True si aucune erreur
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBDZl1s5PQ6TREMrz-OmiXLu2dDtIGkm8Q', // Remplacez par votre clé API Google Maps
    libraries,
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const isDateTimeValid = (date, time) => {
    const now = new Date(); // Date et heure actuelles
    const selectedDateTime = new Date(`${date}T${time}`);
  
    // Vérifier si la date/heure sélectionnée est dans le passé
    return selectedDateTime >= now;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
  
    // Vérification de la date et de l'heure
    if (!formData.date) {
      newErrors.date = "La date est requise.";
    } else if (!isDateTimeValid(formData.date, formData.departure_time)) {
      newErrors.date = "La date et l'heure doivent être dans le futur.";
    }
  
    if (!formData.departure_time) {
      newErrors.departure_time = "L'heure est requise.";
    }
  
    // Vérification des autres champs obligatoires
    if (!formData.adresse_start) {
      newErrors.adresse_start = "L'adresse de départ est requise.";
    }
  
    if (formData.type_travel === "simple journey" && !formData.adresse_end) {
      newErrors.adresse_end = "L'adresse d'arrivée est requise.";
    }
  
    // Si des erreurs sont détectées, les afficher
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Mettre à jour les erreurs
      setOpen(true); // Afficher l'alerte globale
      console.log("Des erreurs ont été détectées :", newErrors);
      return;
    }
  
    // Si tout est valide, soumettre le formulaire
    setIsSubmitting(true);
    try {
      await onSubmit(formData); // Appeler la fonction de soumission
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire :", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const today = new Date().toISOString().split('T')[0];

  const addHours = (date, hours) => {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
  };

  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours-1}:${minutes}`;
  };

  const now = new Date();
  const minTime = new Date(now.getTime() + 60 * 60 * 1000); // 1 heure dans le futur
  const minTimeString = minTime.toISOString().split("T")[1].slice(0, 5); // Format hh:mm

  const handlePlaceChanged = (field, autocomplete) => () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
  
      let streetNumber = '';
      let streetName = '';
      let city = '';
      let postalCode = '';
      let country = '';
  
      addressComponents.forEach((component) => {
        if (component.types.includes('street_number')) {
          streetNumber = component.long_name;
        }
        if (component.types.includes('route')) {
          streetName = component.long_name;
        }
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        }
        if (component.types.includes('country')) {
          country = component.long_name;
        }
      });
  
      const formattedAddress = `${streetNumber} ${streetName}, ${postalCode} ${city}, ${country}`;
  
      setFormData(prevData => ({
        ...prevData,
        [field]: formattedAddress,
        [field.includes('start') ? 'city_start' : 'city_end']: city,
        [field.includes('start') ? 'zipcode_start' : 'zipcode_end']: postalCode,
        [field.includes('start') ? 'country_start' : 'country_end']: country,
      }));
    }
  };
  
  
  const onLoadStart = (autocompleteInstance) => {
    setAutocompleteStart(autocompleteInstance);
  };

  const onLoadEnd = (autocompleteInstance) => {
    setAutocompleteEnd(autocompleteInstance);
  };

  /* NE PAS SUPPRIMER / LABEL MISE A DISPO
              <label className={formData.type_travel === 'miseAdispo' ? 'active' : ''}>
              <input
                type="radio"
                value="miseAdispo"
                checked={formData.type_travel === 'miseAdispo'}
                onChange={handleChange}
                name='type_travel'
              />
              Mise à disposition
            </label>
  */

  /*       {open && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
            Une erreur est survenue avec le formulaire
          </Alert>
        </Snackbar>
      )}

  */

  return isLoaded ? (
    <>
      <Box sx={{ width: '100%' }} className="stepper">
        <Stepper activeStep={0} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <div className='FormContent'>
        <form onSubmit={handleFormSubmit}>
          <div className='FormTravelType'>
            <label className={formData.type_travel === 'simple journey' ? 'active' : ''}>
              <input
                type="radio"
                value="simple journey"
                checked={formData.type_travel === 'simple journey'}
                onChange={handleChange}
                name='type_travel'
              />
              Trajet Simple
            </label>

          </div>

          <div className='FormLocation          '>
            <div className='FormLocationDeparture'>
              <label>Départ</label>
              <Autocomplete onLoad={onLoadStart} onPlaceChanged={handlePlaceChanged('adresse_start', autocompleteStart)}>
                <TextField
                  id="outlined-basic"
                  label="Adresse de départ"
                  variant="outlined"
                  name="adresse_start"
                  value={formData.adresse_start}
                  onChange={handleChange}
                  className='textFieldDeparture'
                  error={!!errors.adresse_start} // Indique une erreur
                  helperText={errors.adresse_start} // Affiche le message d'erreur
                />
              </Autocomplete>
            </div>

            {formData.type_travel === 'simple journey' ? (
              <div className='FormLocationArrival'>
                <label>Destination</label>
                <Autocomplete onLoad={onLoadEnd} onPlaceChanged={handlePlaceChanged('adresse_end', autocompleteEnd)}>
                  <TextField
                    id="outlined-basic"
                    label="Adresse d'arrivée"
                    variant="outlined"
                    name="adresse_end"
                    value={formData.adresse_end}
                    onChange={handleChange}
                    className='textFieldArrival'
                    error={!!errors.adresse_end} // Indique une erreur
                    helperText={errors.adresse_end} // Affiche le message d'erreur
                  />
                </Autocomplete>
              </div>
            ) : (
              <div className='FormDuration'>
                <label>Durée</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.duration}
                  label="duration"
                  name="duration"
                  onChange={handleChange}
                  className='DurationSelect'
                >
                  <MenuItem value={3}>3 Heures</MenuItem>
                  <MenuItem value={4}>4 Heures</MenuItem>
                  <MenuItem value={5}>5 Heures</MenuItem>
                  <MenuItem value={6}>6 Heures</MenuItem>
                  <MenuItem value={7}>7 Heures</MenuItem>
                  <MenuItem value={8}>8 Heures</MenuItem>
                  <MenuItem value={9}>9 Heures</MenuItem>
                  <MenuItem value={10}>10 Heures</MenuItem>
                </Select>
              </div>
            )}
          </div>

          <div className='FormData'>
            <div className='FormDataDateTime'>
              <div className='FormDataDate'>
                <label>Date</label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  min={today}
                />
                {errors.date && <span className="error">{errors.date}</span>}
              </div>
              <div className='FormDataTime'>
                <label>Horaire de départ</label>
                <input
                  type="time"
                  name="departure_time"
                  value={formData.departure_time}
                  onChange={handleChange}
                />
                {errors.departure_time && <span className="error">{errors.departure_time}</span>}
              </div>
            </div>

            <div className='FormDataCarType'>
              <label>Choisir une Voiture</label>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="type_car"
                value={formData.type_car}
                onChange={handleChange}
              >
                <FormControlLabel value="eco" control={<Radio />} label="Economie" />
                <FormControlLabel value="business_van" control={<Radio />} label="Business Van" />
                <FormControlLabel value="premium" control={<Radio />} label="Premium" />
                <FormControlLabel value="business_car" control={<Radio />} label="Business Car" />
              </RadioGroup>
            </div>
          </div>

            
          {isSubmitting ? (
            <div className="loader">Patienter un instant...</div> // Remplacez par votre composant ou animation de loader
          ) : (
            <button
              type="submit"
              className="submitButton"
              onClick={handleFormSubmit}
            >
              Suivant
            </button>
          )}
          
        </form>
      </div>
    </>
  ) : <div>Loading...</div>;
}

